import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { theme } from "./CustomTheme";
import { ThemeProvider } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import ReactGA from "react-ga";
import Home from "./Home";
import Header from "./Header";
import history from "../history";
import Footer from "./Footer/footer";
import ProjectListing from "./ProjectListing/ProjectListing";
import ContactUs from "./ContactUs/ContactUs";
import Chatbot from "./Chatbot/Chatbot";
import AboutUs from "./ContactUs/AboutUs";
import PrivacyPolicy from "./ContactUs/PrivacyPolicy";
import TermsOfUse from "./ContactUs/TermsOfUse";
import Disclaimer from "./ContactUs/Disclaimer";
import Faq from "./FAQ/faq";
import Survey from "./SurveyPage/Survey";
import "./Common/common.scss";
import RajHomes127 from "./ProjectListing/Projects/Properties/RajHomes127";
import RajAkshay from "./ProjectListing/Projects/Properties/RajAkshay";
import RajFlorenza from "./ProjectListing/Projects/Properties/RajFlorenza";
import RajHeritage1 from "./ProjectListing/Projects/Properties/RajHeritage1";
import RajHeritage2 from "./ProjectListing/Projects/Properties/RajHeritage2";
import RajHomes from "./ProjectListing/Projects/Properties/RajHomes";
import RajRudraksha from "./ProjectListing/Projects/Properties/RajRudraksha";
import RajAntila from "./ProjectListing/Projects/Properties/RajAntila";
import RajEstate from "./ProjectListing/Projects/Properties/RajEstate";
import RajHorizon from "./ProjectListing/Projects/Properties/RajHorizon";
import RajExotica from "./ProjectListing/Projects/Properties/RajExotica";
import RajAtlantis from "./ProjectListing/Projects/Properties/RajAtlantis";
import ShreeShashwat from "./ProjectListing/Projects/Properties/ShreeShashwat";
import GNResidency from "./ProjectListing/Projects/Properties/GNResidency";
import RajLifestyle from "./ProjectListing/Projects/Properties/RajLifestyle";

const App = (props) => {
  useEffect(() => {
    ReactGA.initialize("UA-177532077-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    history.listen((location) => {
      window.ga("set", "page", location.pathname + location.search);
      window.ga("send", "pageview");
    });
  }, []);

  return (
    <Router history={history} forceRefresh={true}>
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth="xl">
          <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/allprojects" exact component={ProjectListing} />
            <Route path="/currentprojects" exact component={ProjectListing} />
            <Route path="/completedprojects" exact component={ProjectListing} />
            <Route path="/contact-us" exact component={ContactUs} />
            <Route path="/about-us" exact component={AboutUs} />
            <Route path="/terms-of-use" exact component={TermsOfUse} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/disclaimer" exact component={Disclaimer} />
            <Route path="/faq" exact component={Faq} />
            <Route path="/survey-page" exact component={Survey} />
            <Route path="/allprojects/127rajhomes" exact component={RajHomes127} />
            <Route path="/allprojects/rajakshay" exact component={RajAkshay} />
            <Route
              path="/allprojects/rajflorenza"
              exact
              component={RajFlorenza}
            />
            <Route
              path="/allprojects/RajHeritage1"
              exact
              component={RajHeritage1}
            />
            <Route
              path="/allprojects/RajHeritage2"
              exact
              component={RajHeritage2}
            />
            <Route path="/allprojects/rajhomes" exact component={RajHomes} />
            <Route
              path="/allprojects/rajrudraksha"
              exact
              component={RajRudraksha}
            />
            <Route path="/allprojects/rajantila" exact component={RajAntila} />
            <Route path="/allprojects/rajestate" exact component={RajEstate} />
            <Route
              path="/allprojects/rajhorizon"
              exact
              component={RajHorizon}
            />
            <Route
              path="/allprojects/rajexotica"
              exact
              component={RajExotica}
            />
            <Route
              path="/allprojects/rajatlantis"
              exact
              component={RajAtlantis}
            />
            <Route
              path="/allprojects/shreeshashwat"
              exact
              component={ShreeShashwat}
            />
            <Route
              path="/allprojects/gnresidency"
              exact
              component={GNResidency}
            />
            <Route
              path="/allprojects/rajlifestyle"
              exact
              component={RajLifestyle}
            />
          </Switch>
          {/* <Chatbot /> */}
          <Footer />
        </Container>
      </ThemeProvider>
    </Router>
  );
};

export default App;
