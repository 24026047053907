import "./CardComponent.scss";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import ArrowAnimation from "./ArrowAnimation";
import ReactGA from "react-ga";

const AllProjects = () => {
	const ClickHandler = () => {
		ReactGA.event({
			category: "Button",
			action: "Clicked All Projects button on Home Page",
		});
	};

	return (
		<div className='all-project-div'>
			<Link to='/allprojects/' style={{ textDecoration: "none" }}>
				<Button
					className='all-projects-button'
					variant='outlined'
					onClick={ClickHandler}
				>
					<Typography variant='h5' align='center'>
						All Projects
					</Typography>
					<ArrowAnimation />
				</Button>
			</Link>
		</div>
	);
};

export default AllProjects;
