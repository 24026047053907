import React from "react";
import GNResidency from "../../images/completedProjects/GNResidency.jpg";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SchoolIcon from "@material-ui/icons/School";
import PoolIcon from "@material-ui/icons/Pool";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";

export const heroImage = {
	imageUrl:GNResidency,
	pdfUrl:""
}

export const locationDetails = {
	name: "G.N.Residency",
	lat: "19.2790215",
	lng: "72.8712268",
	googleMapUrl:
		"https://www.google.com/maps/place/G.N.Residency,+A+wing/@19.2790215,72.8712268,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b1e2b7ad9879:0xebe5919467c0713b!8m2!3d19.2790215!4d72.8734155",
};

export const photos = [
	{
		src: GNResidency,
		width: 8,
		height: 3,
		title: "Full View",
	},
];

export const amenities = [
	{
		icon: <SchoolIcon fontSize='large' />,
		name: "Schools Nearby",
	},
	{
		icon: <DirectionsBusIcon fontSize='large' />,
		name: "Bus-stops Nearby",
	},
	{
		icon: <ShoppingCartIcon fontSize='large' />,
		name: "Markets Nearby",
	},
	{
		icon: <FitnessCenterIcon fontSize='large' />,
		name: "Gymnasium",
	},
	{
		icon: <PoolIcon fontSize='large' />,
		name: "Swimming Pool",
	},
];

export const propertyOverview = {
	propertyName: "G.N.Residency",
	overview:
		"The project, G.N. Residency offers spacious thoughtfully planned apartments, each equipped with the finest of amenities and facilities. The Vaastu compliant homes are properly ventilated and offer fresh breeze and natural light to its residents. The homes are very spacious and offer unimaginable level of privacy. The interiors perfectly compliment the marvelous exteriors. Here, the occupants experience a luxurious life at most the affordable rate. Stay at this majestic residential structure and soak in the immense pleasures of life.",
	facilities:
		"Premium quality flooring.\nPremium quality wall tiles in all bathrooms and kitchen.\nGranite kitchen platform with S.S. Sink.\nBranded fittings in all bathrooms.\nConcealed copper wiring with branded electrical fitting\nAnodised / Powder Coated Aluminium sliding windows with mosquito net.\nProvision for cable TV and Telephone connection.",
	maharerano: "P51700019990",
};
