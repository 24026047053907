import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#9e9e9e",
      dark: "#E00000",
      contrastText: "#000",
    },
    secondary: {
      main: "#E00000",
      contrastText: "#fff",
    },
  },
});
