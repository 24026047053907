import React, { Component } from "react";
import { Container } from "@material-ui/core";

import { heroImage, photos,amenities,propertyOverview,locationDetails } from "../../../../Data/Projects/RajEstate";
import ImageGallery from "../Imagegallary";
import PropertyOverview from "../PropertyOverview";
import Amenities from "../Amenities";
import Map from "../LocationMap";
import HeroImage from "../HeroImage";

export class RajEstate extends Component {
	render() {
		return (
			<div className='project-detail'>
				<HeroImage heroImage={heroImage}/>
				<Container>
				    <PropertyOverview overview={propertyOverview} />
					<ImageGallery photos={photos} />
					<Amenities amenities={amenities} />
					
				</Container>
				<Map
					lat={locationDetails.lat}
					lng={locationDetails.lng}
					name={locationDetails.name}
					googleMapUrl={locationDetails.googleMapUrl}
				/>
			</div>
		);
	}
}

export default RajEstate;