import "./Common.scss";
import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import DirectionsIcon from "@material-ui/icons/Directions";
class Map extends Component {
	constructor(props) {
		super(props);

		this.platform = null;
		this.map = null;
		this.state = {
			apikey: "5RV2iNi5jrcjIBDYXklxdfpIEL4Xcdp8iU8D1agfI04",
			center: {
				lat: props.lat,
				lng: props.lng,
			},
			zoom: "16",
			theme: "normal.day",
			style: "default",
			propertyName: this.props.name,
		};
	}

	// TODO: Add theme selection discussed later HERE

	componentDidMount() {
		this.platform = new window.H.service.Platform(this.state);

		var layer = this.platform.createDefaultLayers();
		var container = document.getElementById("here-map");

		this.map = new window.H.Map(container, layer.vector.normal.map, {
			center: this.state.center,
			zoom: this.state.zoom,
		});

		// Create a marker icon from an image URL:
		var icon = new window.H.map.DomIcon(
			'<svg weight="100px" height="100px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" style="enable-background:new 0 0 464 464;" xml:space="preserve">' +
				'<path style="fill:#FDBD40;" d="M144,48h176v400H144V48z"/>' +
				'<path style="fill:#5A6470;" d="M208,352h48v96h-48V352z"/>' +
				'<path style="fill:#A7C6D3;" d="M0,224h144v208H0V224z"/>' +
				'<path style="fill:#CFDFE8;" d="M16,128h96v96H16V128z"/>' +
				'<path style="fill:#A7C6D3;" d="M32,72h64v56H32V72z"/>' +
				'<path style="fill:#5A6470;" d="M56,16h16v56H56V16z"/>' +
				'<path style="fill:#E9686A;" d="M320,224h144v208H320V224z"/>' +
				'<path style="fill:#F98C96;" d="M448,224h-96V72l96,32V224z"/>' +
				"<g>" +
				'<path style="fill:#5A6470;" d="M376,120h16v104h-16V120z"/>' +
				'<path style="fill:#5A6470;" d="M408,120h16v104h-16V120z"/>' +
				'<path style="fill:#5A6470;" d="M24,248h96v16H24V248z"/>' +
				'<path style="fill:#5A6470;" d="M24,280h96v16H24V280z"/>' +
				'<path style="fill:#5A6470;" d="M24,312h96v16H24V312z"/>' +
				'<path style="fill:#5A6470;" d="M24,344h96v16H24V344z"/>' +
				'<path style="fill:#5A6470;" d="M184,96h32v32h-32V96z"/>' +
				'<path style="fill:#5A6470;" d="M248,96h32v32h-32V96z"/>' +
				'<path style="fill:#5A6470;" d="M184,160h32v32h-32V160z"/>' +
				'<path style="fill:#5A6470;" d="M248,160h32v32h-32V160z"/>' +
				'<path style="fill:#5A6470;" d="M184,224h32v32h-32V224z"/>' +
				'<path style="fill:#5A6470;" d="M248,224h32v32h-32V224z"/>' +
				'<path style="fill:#5A6470;" d="M184,288h32v32h-32V288z"/>' +
				'<path style="fill:#5A6470;" d="M248,288h32v32h-32V288z"/>' +
				'<path style="fill:#5A6470;" d="M368,384h48v48h-48V384z"/>' +
				'<path style="fill:#5A6470;" d="M344,248h16v16h-16V248z"/>' +
				'<path style="fill:#5A6470;" d="M376,248h32v16h-32V248z"/>' +
				'<path style="fill:#5A6470;" d="M424,248h16v16h-16V248z"/>' +
				'<path style="fill:#5A6470;" d="M344,280h16v16h-16V280z"/>' +
				'<path style="fill:#5A6470;" d="M376,280h32v16h-32V280z"/>' +
				'<path style="fill:#5A6470;" d="M424,280h16v16h-16V280z"/>' +
				'<path style="fill:#5A6470;" d="M344,312h16v16h-16V312z"/>' +
				'<path style="fill:#5A6470;" d="M376,312h32v16h-32V312z"/>' +
				'<path style="fill:#5A6470;" d="M424,312h16v16h-16V312z"/>' +
				'<path style="fill:#5A6470;" d="M344,344h16v16h-16V344z"/>' +
				'<path style="fill:#5A6470;" d="M376,344h32v16h-32V344z"/>' +
				'<path style="fill:#5A6470;" d="M424,344h16v16h-16V344z"/>' +
				`<text y="420" style="dominant-baseline: text-before-edge;font-size:200px" textLength="100%" lengthAdjust="spacingAndGlyphs" fill="black">${this.state.propertyName}</text>` +
				"</svg>"
		);

		// Create a marker using the previously instantiated icon:
		var marker = new window.H.map.DomMarker(
			{ lat: this.state.center.lat, lng: this.state.center.lng },
			{ icon: icon }
		);

		// Add the marker to the map:
		this.map.addObject(marker);

		var events = new window.H.mapevents.MapEvents(this.map);
		// eslint-disable-next-line
		var behavior = new window.H.mapevents.Behavior(events);
		//eslint - disable - next - line;
		var ui = new window.H.ui.UI.createDefault(this.map, layer);
	}

	render() {
		const googleMapUrl = this.props.googleMapUrl;
		return (
			<>
				<div className='outer-header-div'>
					<div className='section-title-block'>
						<Typography variant='h5' align='center'>
							Location
						</Typography>
						<div className='slide-border'>
							<div className='before'></div>
							<hr className='hr' />
							<div className='after'></div>
						</div>
					</div>
				</div>
				<div
					id='here-map'
					style={{ width: "100%", height: "400px", background: "grey" }}
				/>
				<div className='direction'>
					<a target='_blank' href={googleMapUrl}>
						<span className='icon-location-direction'>
							<DirectionsIcon viewBox='0 -2 24 24' />
							<span>DIRECTIONS</span>
						</span>
					</a>
				</div>
			</>
		);
	}
}

export default Map;
