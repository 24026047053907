import React from "react";
import styled from "styled-components";
import "./collapse.scss";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useSpring, animated } from "react-spring";
import { Link } from "react-router-dom";

const CollapseMenu = (props) => {
	const { open } = useSpring({ open: props.navbarState ? 0 : 1 });

	if (props.navbarState === true) {
		return (
			<CollapseWrapper
				style={{
					transform: open
						.interpolate({
							range: [0, 0.2, 0.3, 1],
							output: [0, -20, 0, -200],
						})
						.interpolate((openValue) => `translate3d(0, ${openValue}px, 0`),
				}}
			>
				<NavLinks>
					<li>
						<Link to='/' onClick={props.handleNavbar} className='level1-menu'>
							Home
						</Link>
						<ul className='submenu'>
							<div className='level2-menu'>
								<li>Who We Are</li>
							</div>
							<div className='level3-menu'>
								<Link to='/about-us' onClick={props.handleNavbar}>
									<li>
										<ArrowRightIcon fontSize='medium' viewBox='0 -5 24 24' />
										About Us
									</li>
								</Link>
								<Link to='/about-us' onClick={props.handleNavbar}>
									<li>
										<ArrowRightIcon fontSize='medium' viewBox='0 -5 24 24' />
										Vision and Mission
									</li>
								</Link>
							</div>
						</ul>
					</li>
					<li>
						<Link to='/currentprojects' onClick={props.handleNavbar}>
							Our Projects
						</Link>
						<ul className='submenu'>
							<li>What we do</li>
							<div className='level3-menu'>
								<Link to='/currentprojects' onClick={props.handleNavbar}>
									<li>
										<ArrowRightIcon fontSize='medium' viewBox='0 -5 24 24' />
										Current Projects
									</li>
								</Link>
								<Link to='/completedprojects' onClick={props.handleNavbar}>
									<li>
										<ArrowRightIcon fontSize='medium' viewBox='0 -5 24 24' />
										Completed Projects
									</li>
								</Link>
								<Link to='/allprojects' onClick={props.handleNavbar}>
									<li>
										<ArrowRightIcon fontSize='medium' viewBox='0 -5 24 24' />
										All Projects
									</li>
								</Link>
							</div>
						</ul>
					</li>
					<li>
						<Link to='/contact-us' onClick={props.handleNavbar}>
							Contact us
						</Link>
					</li>
				</NavLinks>
			</CollapseWrapper>
		);
	}
	return null;
};

export default CollapseMenu;

const CollapseWrapper = styled(animated.div)`
	background: gray;
	position: fixed;
	top: 0rem;
	left: 0;
	right: 0;
	height: -webkit-fill-available;
	transition: all 100ms linear;
	z-index: 11;
`;

const NavLinks = styled.ul`
	list-style-type: none;
	padding: 4.2rem 1rem 2rem 2rem;

	& li {
		transition: all 700ms ease-in 0.4s;
	}

	& a {
		font-size: 15px;
		line-height: 2;
		color: #fff;

		text-decoration: none;
		cursor: pointer;

		&:hover {
			color: #fdcb6e;
		}
	}
`;
