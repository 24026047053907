import React from "react";
import classNames from "classnames";
import aboutus from "../../assets/aboutus.jpg";
import "./AboutUs.scss";
import { Container } from "@material-ui/core";
/*

THE TEAM
Driven by determination, dedication and cohesive action under the guiding vision of its founders Mr.
Rajesh Singh, Mr. Mukesh Parmar, Mr. Suresh Singh and Mr. Ashish Thakkar, the Raj Realty Group
today has a well stocked folio of residential projects in locations like Andheri, Borivali, Dahisar,
Miraroad, Virar and Palghar, projects covering more than 1 million sq. feet of real estate paradise.
With more than 100 years of combined experience in creating architectural landmarks in living
spaces, the founders of Raj Realty Group have firmly established themselves as a leading player in
the construction arena, imprinting its indelible mark across the Mumbai Metropolitan Region.
*/
const AboutUs = () => {
	return (
		<div className='about-us'>
			<img src={aboutus} alt='About=us' className='aboutus-img' />
			<Container>
				<div className='section company-overview'>
					<h2>About Us </h2>
					<p>
						Dream gives rise to hopes, hope inspires efforts and efforts make dreams real.
					</p>

					<p>
						<b>PROMISES PER SQ. FT. DELIVERED.</b>
						Since its inception, Raj Realty holds the distinction of being one of the top Builders and Developers in
						the Mira-Bhayander region, having successfully executed over 30 projects in the western suburbs of
						Mumbai and beyond. Focused on growth and continuous technical evolution, the group strives for
						timely execution, benchmark quality and customer satisfaction in each of its projects. With a core
						team of highly qualified experts and professionals, the group has successfully carved a reputation for
						themselves as highly dependable and trustworthy developers.
					</p>
				</div>
				<div className={classNames("section", "section-vision")}>
					<h2>OUR VISION</h2>
					<p>
						To build India's most admired real estate company, admired not just for its scale, profitability, but
						also for its impact on the lives of its customers, the progress of our country.
					</p>
				</div>
				<div className={classNames("section", "section-mission")}>
					<h2>OUR MISSION</h2>
					<p>
					Fulfill consumers’ aspirations through outstanding design, exceptional service and creating vibrant communities.
					</p>
					<p>"Be the most trusted partner for all our stakeholders"</p>
					<p>
						"Excel in project execution and achieve the highest standards of
						timeliness, quality and safety"
					</p>
					<p>"Be one of the “Great Places to Work”"</p>
				</div>

				<div className={classNames("section", "section-vision")}>
					<h2>THE TEAM</h2>
					<p>
						Driven by determination, dedication and cohesive action under the guiding vision of its founders Mr.
						Rajesh Singh, Mr. Mukesh Parmar, Mr. Suresh Singh and Mr. Ashish Thakkar, the Raj Realty Group
						today has a well stocked folio of residential projects in locations like Andheri, Borivali, Dahisar,
						Miraroad, Virar and Palghar, projects covering more than 1 million sq. feet of real estate paradise.
					</p>
					<p>
						With more than 100 years of combined experience in creating architectural landmarks in living
						spaces, the founders of Raj Realty Group have firmly established themselves as a leading player in
						the construction arena, imprinting its indelible mark across the Mumbai Metropolitan Region.
					</p>
				</div>

			</Container>
		</div>
	);
};

export default AboutUs;
