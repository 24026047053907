import "./slider.scss";
import "../ProjectListing/Projects/Common.scss";
import history from "../../history";
import React from "react";
import { slides } from "../../Data/FeaturedProjectList";
import "react-awesome-slider/dist/styles.css";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import AnimationStyles from "react-awesome-slider/dist/custom-animations/cube-animation.css";
import "react-awesome-slider/dist/captioned.css";
import CoreStyles from "react-awesome-slider/dist/styles.css";
import MoreIcon from "@material-ui/icons/More";
import CallIcon from "@material-ui/icons/Call";
import {
	ButtonContainedPrimary,
	ButtonContainedSecondary,
} from "../Common/Button";
import EnquiryComponent from "./EnquiryComponent";
import { Typography } from "@material-ui/core";
import ReactGA from "react-ga";
const AutoplaySlider = withAutoplay(AwesomeSlider);

const GetSlides = () => {
	const ClickHandler = () => {
		ReactGA.event({
			category: "Button",
			action: "Clicked Know More button on FeaturedProjects Slider",
		});
	};
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return slides.map((slide) => (
		<div key={slide.title} className='property-image' data-src={slide.image}>
			<div className='property-details' data-type='caption'>
				<ButtonContainedPrimary
					endIcon={<MoreIcon />}
					style={{ marginRight: "2%" }}
					onClick={() => {
						ClickHandler();
						history.push(slide.url);
					}}
				>
					{slide.title}
				</ButtonContainedPrimary>

				<ButtonContainedSecondary endIcon={<CallIcon />} onClick={handleOpen}>
					I am intrested.
				</ButtonContainedSecondary>
				<EnquiryComponent
					openModal={open}
					closeModal={handleClose}
					propertyName={slide.title}
				/>
			</div>
		</div>
	));
};

const getSlider = () => {
	return (
		<AutoplaySlider
			play={false}
			cancelOnInteraction={true}
			interval={4000}
			animation='cubeAnimation'
			cssModule={[AnimationStyles, CoreStyles]}
			className='aws-btn'
		>
			{GetSlides()}
		</AutoplaySlider>
	);
};

export default function Slider() {
	return (
		<div className='featured-slider'>
			<div className='outer-header-div'>
				<div className='section-title-block'>
					<Typography variant='h5' align='center'>
						Ongoing Projects
					</Typography>
					<div className='slide-border'>
						<div className='before'></div>
						<hr className='hr' />
						<div className='after'></div>
					</div>
				</div>
			</div>
			{getSlider()}
		</div>
	);
}
