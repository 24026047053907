import RajRudraksha from "../../images/onGoingProjects/RajRudraksha.jpg";
import RajRudrakshPDF from "../../assets/pdf/RajRudraksha.pdf";
import DirectionsRailwayIcon from "@material-ui/icons/DirectionsRailway";
import SchoolIcon from "@material-ui/icons/School";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import DeckIcon from "@material-ui/icons/Deck";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import VideocamIcon from "@material-ui/icons/Videocam";
import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import React from "react";

import EntranceLobby from "../../images/projectDetails/RajRudraksha/MainLobby.jpg";
import FloorLobby from "../../images/projectDetails/RajRudraksha/FloorLobby.jpg";
import LivingRoom1 from "../../images/projectDetails/RajRudraksha/LivingRoom1.jpg";
import LivingRoom2 from "../../images/projectDetails/RajRudraksha/LivingRoom2.jpg";
import LivingRoom3 from "../../images/projectDetails/RajRudraksha/LivingRoom3.jpg";
import LivingRoom4 from "../../images/projectDetails/RajRudraksha/LivingRoom4.jpg";
import LivingRoom5 from "../../images/projectDetails/RajRudraksha/LivingRoom5.jpg";
import Bedroom from "../../images/projectDetails/RajRudraksha/Bedroom.jpg";
import Bedroom1 from "../../images/projectDetails/RajRudraksha/Bedroom1.jpg";
import Bedroom4 from "../../images/projectDetails/RajRudraksha/Bedroom4.jpg";
import Bedroom5 from "../../images/projectDetails/RajRudraksha/Bedroom5.jpg";
import Bedroom7 from "../../images/projectDetails/RajRudraksha/Bedroom7.jpg";
import Bedroom9 from "../../images/projectDetails/RajRudraksha/Bedroom9.jpg";
import Bedroom11 from "../../images/projectDetails/RajRudraksha/Bedroom11.jpg";
import MasterBedroom1 from "../../images/projectDetails/RajRudraksha/MasterBedroom1.jpg";
import MasterBedroom2 from "../../images/projectDetails/RajRudraksha/MasterBedroom2.jpg";
import Kitchen from "../../images/projectDetails/RajRudraksha/Kitchen.jpg";
import Kitchen1 from "../../images/projectDetails/RajRudraksha/Kitchen1.jpg";
import Kitchen2 from "../../images/projectDetails/RajRudraksha/Kitchen2.jpg";
import Commonbathroom from "../../images/projectDetails/RajRudraksha/Commonbathroom.jpg";
import MasterBathroom from "../../images/projectDetails/RajRudraksha/MasterBathroom.jpg";
import RudrakshGateView1 from "../../images/projectDetails/RajRudraksha/RudrakshGateView1.jpg";
import RudrakshGateView2 from "../../images/projectDetails/RajRudraksha/RudrakshGateView2.jpg";
import RudrakshArialView from "../../images/projectDetails/RajRudraksha/RudrakshArialView.jpg";
import RudrakshEastSideView from "../../images/projectDetails/RajRudraksha/RudrakshEastSideView.jpg";
import RudrakshGardenGateView from "../../images/projectDetails/RajRudraksha/RudrakshGardenGateView.jpg";
import FullView from "../../images/projectDetails/RajRudraksha/FullView.jpg";
import RudrakshWestSideNight from "../../images/projectDetails/RajRudraksha/RudrakshWestSideNight.jpg";
import TopLayout from "../../images/projectDetails/RajRudraksha/TopLayout.jpg";

import FloorPlan from "../../images/floorPlans/RajRudraksha.jpg";

export const heroImage = {
	imageUrl: RajRudraksha,
	pdfUrl: RajRudrakshPDF,
	propertyName: "Raj Rudraksha",
};
export const locationDetails = {
	name: "Raj Rudraksha",
	lat: "19.2510232",
	lng: "72.8698993",
	googleMapUrl:
		"https://www.google.co.in/maps/place/Rudraksha+by+Raj+Realty/@19.2510232,72.8698993,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b0eb83a0ea25:0xd5ae55fb5102dcaa!8m2!3d19.2510181!4d72.872088",
};

export const photos = [
	{
		src: EntranceLobby,
		width: 5,
		height: 3,
		title: "Entrance Lobby",
	},
	{
		src: FloorLobby,
		width: 4,
		height: 3,
		title: "Floor Lobby",
	},
	{
		src: LivingRoom1,
		width: 4,
		height: 3,
		title: "Living Room",
	},
	{
		src: LivingRoom2,
		width: 4,
		height: 3,
		title: "Living Room",
	},
	{
		src: LivingRoom3,
		width: 4,
		height: 3,
		title: "Living Room",
	},
	{
		src: LivingRoom4,
		width: 4,
		height: 3,
		title: "Living Room",
	},
	{
		src: LivingRoom5,
		width: 3,
		height: 4,
		title: "Living Room",
	},
	{
		src: Bedroom,
		width: 4,
		height: 3,
		title: "Bedroom",
	},
	{
		src: Bedroom1,
		width: 4,
		height: 3,
		title: "Bedroom",
	},
	{
		src: Bedroom4,
		width: 3,
		height: 3,
		title: "Bedroom",
	},
	{
		src: Bedroom5,
		width: 3,
		height: 3,
		title: "Bedroom",
	},
	{
		src: Bedroom7,
		width: 3,
		height: 3,
		title: "Bedroom",
	},
	{
		src: Bedroom9,
		width: 3,
		height: 3,
		title: "Bedroom",
	},
	{
		src: Bedroom11,
		width: 3,
		height: 3,
		title: "Bedroom",
	},
	{
		src: MasterBedroom1,
		width: 4,
		height: 3,
		title: "Master Bedroom",
	},
	{
		src: MasterBedroom2,
		width: 4,
		height: 3,
		title: "Master Bedroom",
	},
	{
		src: Kitchen,
		width: 3,
		height: 3,
		title: "Kitchen",
	},
	{
		src: Kitchen1,
		width: 3,
		height: 3,
		title: "Kitchen",
	},
	{
		src: Kitchen2,
		width: 3,
		height: 3,
		title: "Kitchen",
	},
	{
		src: Commonbathroom,
		width: 3,
		height: 3,
		title: "Common bathroom",
	},
	{
		src: MasterBathroom,
		width: 3,
		height: 3,
		title: "Master Bathroom",
	},
	{
		src: RudrakshGateView1,
		width: 4,
		height: 3,
		title: "Gate View",
	},
	{
		src: RudrakshGateView2,
		width: 4,
		height: 3,
		title: "Gate View",
	},
	{
		src: RudrakshArialView,
		width: 4,
		height: 3,
		title: "Arial View",
	},
	{
		src: RudrakshEastSideView,
		width: 4,
		height: 3,
		title: "East Side View",
	},
	{
		src: RudrakshWestSideNight,
		width: 4,
		height: 3,
		title: "West Side Night View",
	},
	{
		src: RudrakshGardenGateView,
		width: 4,
		height: 3,
		title: "Garden Gate View",
	},
	{
		src: FullView,
		width: 4,
		height: 3,
		title: "Full View",
	},
	{
		src: TopLayout,
		width: 4,
		height: 3,
		title: "Top Layout View",
	},
];

export const prices = [
	{
		topology: "1BHK",
		price: "62 Lacs++",
		status: "O.C Received, Ready to move(building no. 11)",
	},
	{
		topology: "2BHK",
		price: "87.2 Lacs++",
		status: "O.C Received, Ready to move(building no. 11)",
	},
];

export const amenities = [
	{
		icon: <DirectionsRailwayIcon fontSize='large' />,
		name: "5mins from railway station",
	},
	{
		icon: <SchoolIcon fontSize='large' />,
		name: "Schools Nearby",
	},
	{
		icon: <DeckIcon fontSize='large' />,
		name: "Childrens Play Area",
	},
	{
		icon: <LocalHospitalIcon fontSize='large' />,
		name: "Hospitals Nearby",
	},
	{
		icon: <PhoneInTalkIcon fontSize='large' />,
		name: "Intercom Facility",
	},
	{
		icon: <VideocamIcon fontSize='large' />,
		name: "CCTV Surveillance",
	},
	{
		icon: <BlurLinearIcon fontSize='large' />,
		name: "Anodised Aluminium SlidingWindow",
	},
];

export const propertyOverview = {
	propertyName: "Raj Rudraksha",
	overview:
		"Raj Realty bring you Rudraksha, a magnificent residential layout of 1 and 2 BHK apartments in Vaishali nagar at Dahisar east. Savour every moment of this new experience in the exquisitely designed homes that open out onto breathtaking views of lush greenery of National Park. Designed with a view to maximise comfort, each room within these spacious apartments offers a unique blend of functionality and sophistication.",
	facilities:
		"Premium quality flooring.\nPremium quality wall tiles in all bathrooms and kitchen.\nGranite kitchen platform with S.S. Sink.\nModular Kitchen.\nKohler fittings in all bathrooms.\nConcealed copper wiring with Roma or equivalent electrical fitting.\nPolyurethane coated Aluminium sliding windows with mosquito net.\nProvision for cable T.V and Telephone connection.\nVideo door phone with Intercom facility.",
	maharerano: "P51800021545",
	topology: "Topology: 1 & 2 BHK Apartments",
	status:
		"Status: Building No.11- Under Construction, Building No.10- Ready to Move",
};

export const floorplan = FloorPlan;
