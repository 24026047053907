import RajAkshay from "../images/onGoingProjects/RajAkshay.jpg";
import RajFlorenza from "../images/onGoingProjects/RajFlorenza.jpg";
import RajHeritage2 from "../images/onGoingProjects/RajHeritage2.jpg";
import RajHomes from "../images/onGoingProjects/RajHomes.jpg";
import RajRudraksha from "../images/onGoingProjects/RajRudraksha.jpg";

export const slides = [
	{
		title: "Raj Akshay",
		image: RajAkshay,
		url: "/allprojects/rajakshay",
	},
	{
		title: "Raj Florenza",
		image: RajFlorenza,
		url: "/allprojects/rajflorenza",
	},
	{
		title: "Raj Rudraksha",
		image: RajRudraksha,
		url: "/allprojects/rajrudraksha",
	},
	{
		title: "Raj Heritage 2",
		image: RajHeritage2,
		url: "/allprojects/RajHeritage2",
	},
	{
		title: "Raj Homes",
		image: RajHomes,
		url: "/allprojects/rajhomes",
	},
];
