import React from "react";
import RajHomes127 from "../images/onGoingProjects/RajHomes127.jpg";
import RajAkshay from "../images/onGoingProjects/RajAkshay.jpg";
import RajFlorenza from "../images/onGoingProjects/RajFlorenza.jpg";
import RajHeritage1 from "../images/onGoingProjects/RajHeritage1.jpg";
import RajHeritage2 from "../images/onGoingProjects/RajHeritage2.jpg";
import RajHomes from "../images/onGoingProjects/RajHomes.jpg";
import RajRudraksha from "../images/onGoingProjects/RajRudraksha.jpg";
import RajAntila from "../images/completedProjects/RajAntila.jpg";
import RajExotica from "../images/projectDetails/RajExotica/RajExotica.jpg";
import ShreeShaswat from "../images/projectDetails/ShreeShaswat/GardenView.jpg";
import GNResidency from "../images/completedProjects/GNResidency.jpg";
import RajHorizon from "../images/projectDetails/RajHorizon/RajHorizon.jpg";
import RajEstate from "../images/projectDetails/RajEstate/RajEstate.jpg";
import RajAtlantis from "../images/projectDetails/RajAtlantis/RajAtlantis.jpg";
import RajLifestyle from "../images/projectDetails/RajLifestyle/NightView.jpg";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import HouseIcon from "@material-ui/icons/House";
import SportsTennisRoundedIcon from "@material-ui/icons/SportsTennisRounded";
import DirectionsRailwayIcon from "@material-ui/icons/DirectionsRailway";
import SchoolIcon from "@material-ui/icons/School";
import PoolIcon from "@material-ui/icons/Pool";
import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import PoolRoundedIcon from "@material-ui/icons/PoolRounded";
import DeckIcon from "@material-ui/icons/Deck";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import VideocamIcon from "@material-ui/icons/Videocam";
import KitchenIcon from "@material-ui/icons/Kitchen";
import GamesIcon from "@material-ui/icons/Games";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";

export const currentProjects = [
	{
		title: "127 Raj Homes",
        shareDescription:'Coming soon...',	
		location: "Mira Road East, Mira Bhayandar",
		status: "Status: Under Construction",
		description: "Coming soon...",
		imageurl: RajHomes127,
		url: "/allprojects/127rajhomes",
		amenities: [
			{
				icon: <DeckIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Garden",
			},
			{
				icon: (
					<FitnessCenterIcon
						viewBox='0 -4 24 24'
						className='card-action-icon'
					/>
				),
				title: "Air Conditioned Gymnasium",
			},
			{
				icon: (
					<SportsTennisRoundedIcon
						viewBox='0 -1 24 24'
						className='card-action-icon'
					/>
				),
				title: "ClubHouse",
			},
			{
				icon: (
					<PoolRoundedIcon viewBox='0 -2 24 24' className='card-action-icon' />
				),
				title: "Swimming Pool",
			},
			{
				icon: (
					<HomeWorkIcon viewBox='0 -2 24 24' className='card-action-icon' />
				),
				title: "Multipurpose Hall",
			},
		],
	},
	{
		title: "Raj Akshay",
        shareDescription:'RAJ AKSHAY offers an excellent choice of well designed 1 & 2 BHK flats outfitted with the best of contemporary in-house amenities and well planned outdoor facilities.',	
		location: "Mira Road East, Mira Bhayandar",
		status: "Status: Under Construction",
		description: "Type of Flats: 1 & 2 BHK Apartments",
		imageurl: RajAkshay,
		url: "/allprojects/rajakshay",
		amenities: [
			{
				icon: <DeckIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Garden",
			},
			{
				icon: (
					<FitnessCenterIcon
						viewBox='0 -4 24 24'
						className='card-action-icon'
					/>
				),
				title: "Air Conditioned Gymnasium",
			},
			{
				icon: (
					<SportsTennisRoundedIcon
						viewBox='0 -1 24 24'
						className='card-action-icon'
					/>
				),
				title: "ClubHouse",
			},
			{
				icon: (
					<PoolRoundedIcon viewBox='0 -2 24 24' className='card-action-icon' />
				),
				title: "Swimming Pool",
			},
			{
				icon: (
					<HomeWorkIcon viewBox='0 -2 24 24' className='card-action-icon' />
				),
				title: "Multipurpose Hall",
			},
		],
	},
	{
		title: "Raj Rudraksha ",
        shareDescription:'Raj realty brings you RUDRAKSHA, a magnificent residential layout of 1 & 2 BHK apartments in Vaishali Nagar at Dahisar East. Savour every moment of this new experience in the exquisitely designed homes that open out onto breathtaking view and lush greenery of National Park. Designed with a view to maximise comfort , each room within these spacious apartments offer a unique blend of functionality and sophistication.',
		location: "Dahisar East, Mumbai",
		status: "Status: Ready to Move ",
		description: "Type of Flats: 1 & 2 BHK Apartments",
		imageurl: RajRudraksha,
		url: "/allprojects/rajrudraksha",
		amenities: [
			{
				icon: (
					<DirectionsRailwayIcon
						viewBox='0 -3 24 24'
						className='card-action-icon'
					/>
				),
				title: "5mins from railway station",
			},
			{
				icon: <SchoolIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Schools Nearby",
			},
			{
				icon: <DeckIcon viewBox='0 -1 24 24' className='card-action-icon' />,
				title: "Childrens Play Area",
			},
			{
				icon: (
					<LocalHospitalIcon
						viewBox='0 -4 24 24'
						className='card-action-icon'
					/>
				),
				title: "Hospitals Nearby",
			},
			{
				icon: (
					<PhoneInTalkIcon viewBox='0 -1 24 24' className='card-action-icon' />
				),
				title: "Intercom Facility",
			},
			{
				icon: (
					<VideocamIcon viewBox='0 -2 24 24' className='card-action-icon' />
				),
				title: "CCTV Surveillance",
			},
			{
				icon: (
					<BlurLinearIcon viewBox='0 -2 24 24' className='card-action-icon' />
				),
				title: "Anodised Aluminium Sliding Windows",
			},
		],
	},
	{
		title: "Raj Heritage 2",
        shareDescription:'Celebrate your arrival at RAJ HERITAGE - 2. A unique brand of lifestyle in Mira Road (E). With resounding architectural , well defined designs and an intricate layout plan, Raj Heritage-2 is a doorway to lavish living.',
		location: "Mira Road East, Mira Bhayandar",
		status: "Status: Under Construction",
		description: "Type of Flats: 1 & 2 BHK Apartments",
		imageurl: RajHeritage2,
		url: "/allprojects/RajHeritage2",
		amenities: [
			{
				icon: <HouseIcon viewBox='0 -3 24 24' className='card-action-icon' />,
				title: "ClubHouse",
			},
			{
				icon: <PoolIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Swimming Pool",
			},
			{
				icon: <DeckIcon viewBox='0 -1 24 24' className='card-action-icon' />,
				title: "Garden",
			},
			{
				icon: (
					<FitnessCenterIcon
						viewBox='0 -4 24 24'
						className='card-action-icon'
					/>
				),
				title: "Gymnasium",
			},
			{
				icon: (
					<MeetingRoomIcon viewBox='0 -1 24 24' className='card-action-icon' />
				),
				title: "Video door camera",
			},
			{
				icon: (
					<PhoneInTalkIcon viewBox='0 -2 24 24' className='card-action-icon' />
				),
				title: "Intercom facility",
			},
			{
				icon: (
					<VideocamIcon viewBox='0 -2 24 24' className='card-action-icon' />
				),
				title: "CCTV Camera",
			},
		],
	},
];

export const completedProjects = [
	{
		title: "Raj Florenza",
        shareDescription:'“A Setting For Serenity” Introducing RAJ FLORENZA, a residential complex at Mira Road, beautifully planned and executed offering by Raj Realty Group hosts spacious well-appointed 1-2 BHK apartments.',
		location: "Mira Road East, Mira Bhayandar",
		status: "Status: Ready to Move",
		description: "Type of Flats: 1 & 2  BHK Apartments",
		imageurl: RajFlorenza,
		url: "/allprojects/rajflorenza",
		amenities: [
			{
				icon: (
					<MeetingRoomIcon viewBox='0 -3 24 24' className='card-action-icon' />
				),
				title: "Video door camera",
			},
			{
				icon: <KitchenIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Modular Kitchen",
			},
			{
				icon: <DeckIcon viewBox='0 -1 24 24' className='card-action-icon' />,
				title: "Garden",
			},
			{
				icon: (
					<VideocamIcon viewBox='0 -4 24 24' className='card-action-icon' />
				),
				title: "CCTV Camera",
			},
			{
				icon: (
					<PhoneInTalkIcon viewBox='0 -1 24 24' className='card-action-icon' />
				),
				title: "Intercom facility",
			},
			{
				icon: (
					<SettingsInputAntennaIcon
						viewBox='0 -2 24 24'
						className='card-action-icon'
					/>
				),
				title: "TV Cable and Telephone connections",
			},
		],
	},
	{
		title: "Raj Homes",
        shareDescription:'Introducing RAJ HOMES, A space reviving the roots of pure living, located at Rai, Gorai - Uttan road. Homes designed with a view to provide you affordable housing in a peaceful setting.',
		location: "Mira Road East, Mira Bhayandar",
		status: "Status: Under Construction",
		description: "Type of Flats: 1 BHK Apartments",
		imageurl: RajHomes,
		url: "/allprojects/rajhomes",
		amenities: [
			{
				icon: <DeckIcon viewBox='0 -3 24 24' className='card-action-icon' />,
				title: "Garden",
			},
			{
				icon: <SchoolIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Schools Nearby",
			},
			{
				icon: (
					<DirectionsBusIcon
						viewBox='0 -2 24 24'
						className='card-action-icon'
					/>
				),
				title: "Bus-stops Nearby",
			},
			{
				icon: (
					<ShoppingCartIcon viewBox='0 -3 24 24' className='card-action-icon' />
				),
				title: "Markets Nearby",
			},
		],
	},
	{
		title: "Raj Heritage 1",
        shareDescription:'Celebrate your arrival at RAJ HERITAGE - 1. A unique brand of lifestyle in Mira Road (E). With resounding architectural , well defined designs and an intricate layout plan, Raj Heritage-1 is a doorway to lavish living.',
		location: "Mira Road East, Mira Bhayandar",
		status: "Status: Under Construction",
		description: "Type of Flats: 1 & 2 BHK Apartments",
		imageurl: RajHeritage1,
		url: "/allprojects/RajHeritage1",
		amenities: [
			{
				icon: <HouseIcon viewBox='0 -3 24 24' className='card-action-icon' />,
				title: "ClubHouse",
			},
			{
				icon: <PoolIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Swimming Pool",
			},
			{
				icon: <DeckIcon viewBox='0 -1 24 24' className='card-action-icon' />,
				title: "Garden",
			},
			{
				icon: (
					<FitnessCenterIcon
						viewBox='0 -4 24 24'
						className='card-action-icon'
					/>
				),
				title: "Gymnasium",
			},
			{
				icon: (
					<MeetingRoomIcon viewBox='0 -1 24 24' className='card-action-icon' />
				),
				title: "Video door camera",
			},
			{
				icon: (
					<PhoneInTalkIcon viewBox='0 -2 24 24' className='card-action-icon' />
				),
				title: "Intercom facility",
			},
			{
				icon: (
					<VideocamIcon viewBox='0 -2 24 24' className='card-action-icon' />
				),
				title: "CCTV Camera",
			},
		],
	},
	{
		title: "GN Residency",
		shareDescription:'Designed to make a difference in the way you view life, adorned with the choices of extreme luxury, comfort and freedom at RajRealty.',
		location: "Mira Road East, Mira Bhayandar",
		status: "Status: Completed",
		description: "Type of Flats: 1 & 2.5 BHK Apartments",
		imageurl: GNResidency,
		url: "/allprojects/gnresidency",
		amenities: [
			{
				icon: <SchoolIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Schools Nearby",
			},
			{
				icon: (
					<DirectionsBusIcon
						viewBox='0 -2 24 24'
						className='card-action-icon'
					/>
				),
				title: "Bus-stops Nearby",
			},
			{
				icon: (
					<ShoppingCartIcon viewBox='0 -3 24 24' className='card-action-icon' />
				),
				title: "Markets Nearby",
			},
		],
	},
	{
		title: "Shree Sashwat",
		shareDescription:'Designed to make a difference in the way you view life, adorned with the choices of extreme luxury, comfort and freedom at RajRealty.',
		location: "Virar",
		status: "Status: Completed",
		description: "Type of Flats: 1 & 2 BHK Apartments",
		imageurl: ShreeShaswat,
		url: "/allprojects/shreeshashwat",
		amenities: [
			{
				icon: <DeckIcon viewBox='0 -3 24 24' className='card-action-icon' />,
				title: "Garden",
			},
			{
				icon: (
					<FitnessCenterIcon
						viewBox='0 -4 24 24'
						className='card-action-icon'
					/>
				),
				title: "Gymnasium",
			},
			{
				icon: <DeckIcon viewBox='0 -1 24 24' className='card-action-icon' />,
				title: "Garden",
			},
			{
				icon: <HouseIcon viewBox='0 -3 24 24' className='card-action-icon' />,
				title: "ClubHouse",
			},
			{
				icon: (
					<HomeWorkIcon viewBox='0 -2 24 24' className='card-action-icon' />
				),
				title: "Multipurpose Hall",
			},
		],
	},
	{
		title: "Raj Exotica",
		shareDescription:'Designed to make a difference in the way you view life, adorned with the choices of extreme luxury, comfort and freedom at RajRealty.',
		location: "Mira Road, Mira Bhayandar",
		status: "Status: Completed",
		description: "Type of Flats: 1,2 & 3 BHK Apartments",
		imageurl: RajExotica,
		url: "/allprojects/rajexotica",
		amenities: [
			{
				icon: <PoolIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Swimming Pool",
			},
			{
				icon: <DeckIcon viewBox='0 -1 24 24' className='card-action-icon' />,
				title: "Childrens Play Area",
			},
			{
				icon: <DeckIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Garden",
			},
			{
				icon: (
					<FitnessCenterIcon
						viewBox='0 -4 24 24'
						className='card-action-icon'
					/>
				),
				title: "Gymnasium",
			},
			{
				icon: <GamesIcon viewBox='0 -4 24 24' className='card-action-icon' />,
				title: "Indoor Games room",
			},
			{
				icon: (
					<VerifiedUserIcon viewBox='0 -4 24 24' className='card-action-icon' />
				),
				title: "24x7 Security",
			},
			{
				icon: (
					<DirectionsBikeIcon
						viewBox='0 -2 24 24'
						className='card-action-icon'
					/>
				),
				title: "Cycling & Jogging track",
			},
		],
	},
	{
		title: "Raj Horizon",
		shareDescription:'Designed to make a difference in the way you view life, adorned with the choices of extreme luxury, comfort and freedom at RajRealty.',
		location: "Mira Road East, Mira Bhayandar",
		status: "Status: Completed",
		description: "Type of Flats: 2.5 & 3 BHK Apartments",
		imageurl: RajHorizon,
		url: "/allprojects/rajhorizon",
		amenities: [
			{
				icon: <PoolIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Swimming Pool",
			},
			{
				icon: (
					<FitnessCenterIcon
						viewBox='0 -4 24 24'
						className='card-action-icon'
					/>
				),
				title: "Gymnasium",
			},
			{
				icon: (
					<VerifiedUserIcon viewBox='0 -4 24 24' className='card-action-icon' />
				),
				title: "24x7 Security",
			},
			{
				icon: (
					<DirectionsBikeIcon
						viewBox='0 -2 24 24'
						className='card-action-icon'
					/>
				),
				title: "Cycling & Jogging track",
			},
		],
	},
	{
		title: "Raj Antila",
		shareDescription:'Designed to make a difference in the way you view life, adorned with the choices of extreme luxury, comfort and freedom at RajRealty.',
		location: "Mira Road, Mira Bhayandar",
		status: "Status: Completed",
		description: "Type of Flats: 1 & 2 BHK Apartments",
		imageurl: RajAntila,
		url: "/allprojects/rajantila",
		amenities: [
			{
				icon: <PoolIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Swimming Pool",
			},
			{
				icon: (
					<FitnessCenterIcon
						viewBox='0 -4 24 24'
						className='card-action-icon'
					/>
				),
				title: "Gymnasium",
			},
			{
				icon: (
					<VerifiedUserIcon viewBox='0 -4 24 24' className='card-action-icon' />
				),
				title: "24x7 Security",
			},
			{
				icon: (
					<DirectionsBikeIcon
						viewBox='0 -2 24 24'
						className='card-action-icon'
					/>
				),
				title: "Cycling & Jogging track",
			},
		],
	},
	{
		title: "Raj Estate",
		shareDescription:'Designed to make a difference in the way you view life, adorned with the choices of extreme luxury, comfort and freedom at RajRealty.',
		location: "Mira Road, Mira Bhayandar",
		status: "Status: Completed",
		description: "Type of Flats: 1 & 2 BHK Apartments",
		imageurl: RajEstate,
		url: "/allprojects/rajestate",
		amenities: [
			{
				icon: <DeckIcon viewBox='0 -1 24 24' className='card-action-icon' />,
				title: "Childrens Play Area",
			},
			{
				icon: (
					<FitnessCenterIcon
						viewBox='0 -4 24 24'
						className='card-action-icon'
					/>
				),
				title: "Gymnasium",
			},
		],
	},
	{
		title: "Raj Atlantis",
		shareDescription:'Designed to make a difference in the way you view life, adorned with the choices of extreme luxury, comfort and freedom at RajRealty.',
		location: "Mira Road, Mira Bhayandar",
		status: "Status: Completed",
		description: "Type of Flats: 3 BHK Apartments",
		imageurl: RajAtlantis,
		url: "/allprojects/rajatlantis",
		amenities: [
			{
				icon: <PoolIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Swimming Pool",
			},
			{
				icon: <SchoolIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Schools Nearby",
			},
			{
				icon: (
					<DirectionsBusIcon
						viewBox='0 -2 24 24'
						className='card-action-icon'
					/>
				),
				title: "Bus-stops Nearby",
			},
			{
				icon: (
					<DirectionsBikeIcon
						viewBox='0 -2 24 24'
						className='card-action-icon'
					/>
				),
				title: "Cycling & Jogging track",
			},
			{
				icon: (
					<VerifiedUserIcon viewBox='0 -4 24 24' className='card-action-icon' />
				),
				title: "24x7 Security",
			},
		],
	},
	{
		title: "Raj Lifestyle",
		shareDescription:'Designed to make a difference in the way you view life, adorned with the choices of extreme luxury, comfort and freedom at RajRealty.',
		location: "Mira Road, Mira Bhayandar",
		status: "Status: Completed",
		description: "Type of Flats: 1,2 & 3 BHK Apartments",
		imageurl: RajLifestyle,
		url: "/allprojects/rajlifestyle",
		amenities: [
			{
				icon: <PoolIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Swimming Pool",
			},
			{
				icon: <SchoolIcon viewBox='0 -2 24 24' className='card-action-icon' />,
				title: "Schools Nearby",
			},
			{
				icon: (
					<DirectionsBusIcon
						viewBox='0 -2 24 24'
						className='card-action-icon'
					/>
				),
				title: "Bus-stops Nearby",
			},
			{
				icon: (
					<DirectionsBikeIcon
						viewBox='0 -2 24 24'
						className='card-action-icon'
					/>
				),
				title: "Cycling & Jogging track",
			},
			{
				icon: (
					<VerifiedUserIcon viewBox='0 -4 24 24' className='card-action-icon' />
				),
				title: "24x7 Security",
			},
		],
	},
];
