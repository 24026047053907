import RajHomes from "../../images/onGoingProjects/RajHomes.jpg";
import RajHomesPDF from "../../assets/pdf/RajHomes.pdf";
import DeckIcon from "@material-ui/icons/Deck";
import SchoolIcon from "@material-ui/icons/School";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import React from "react";

import EntranceLobby from "../../images/projectDetails/RajHomes/EntranceLobby.jpg";
import LivingDining1 from "../../images/projectDetails/RajHomes/LivingDining1.jpg";
import LivingDining2 from "../../images/projectDetails/RajHomes/LivingDining2.jpg";
import Kitchen from "../../images/projectDetails/RajHomes/Kitchen.jpg";
import FullView from "../../images/projectDetails/RajHomes/FullView.jpg";
import FloorPlan from "../../images/floorPlans/RajHomes.jpg";
export const heroImage = {
	imageUrl: RajHomes,
	pdfUrl: RajHomesPDF,
	propertyName: "Raj Homes",
};

export const locationDetails = {
	name: "Raj Homes",
	lat: "19.2786401",
	lng: "72.8712322",
	googleMapUrl:
		"https://www.google.co.in/maps/place/Raj+Realty/@19.2786401,72.8712322,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b04ef51677cd:0xa7d56d5199fc87f!8m2!3d19.278635!4d72.8734209",
};

export const photos = [
	{
		src: EntranceLobby,
		width: 6,
		height: 4,
		title: "Entrance Lobby",
	},
	{
		src: LivingDining1,
		width: 5,
		height: 4,
		title: "Living Dining Room",
	},
	{
		src: LivingDining2,
		width: 5,
		height: 4,
		title: "Living Dining Room",
	},
	{
		src: Kitchen,
		width: 4,
		height: 4,
		title: "Kitchen",
	},
	{
		src: FullView,
		width: 8,
		height: 4,
		title: "FullView",
	},
];

export const prices = [
	{
		topology: "1BHK",
		price: "38 Lacs++",
		status: "45% work completion.",
	},
];

export const amenities = [
	{
		icon: <DeckIcon fontSize='large' />,
		name: "Garden",
	},
	{
		icon: <SchoolIcon fontSize='large' />,
		name: "Schools Nearby",
	},
	{
		icon: <DirectionsBusIcon fontSize='large' />,
		name: "Bus-stops Nearby",
	},
	{
		icon: <ShoppingCartIcon fontSize='large' />,
		name: "Markets Nearby",
	},
];

export const propertyOverview = {
	propertyName: "Raj Homes",
	overview: "Raj Homes Reviving the Roots of pure living",
	facilities:
		"Vitrified flooring in entire flat.\nDecorative laminated main door with teakwood frame and standard safety locks.\nMarble window frame.\nColour anodized aluminium sliding windows with Reflective glass.\nGranite double kitchen platform.\nDesigner tiles up to beam level above kitchen platform. Kitchen with stainless steel sink and trolly cabinet below platform. Concealed copper wiring.\nBranded switches and accessories. Sufficient number of electrical points for convenience.\nA.C. point in bedroom.\nDesigner bathroom with anti skid ceramic tile flooring and full height dado. Concealed plumbing with superior quality sanitary ware and jaquar fittings.\nOne loft tank in each flat plastic paint in intemal wall.\nSuperior quality elevators of reputed make.\nEarthquake resistant design R.C.C. Structure.\nElegant Entrance lobby.",
	maharerano: "P51700019190",
	topology: "Topology: 1 BHK Apartments",
	status: "Status: Under Construction",
};

export const floorplan = FloorPlan;
