import HouseIcon from "@material-ui/icons/House";
import PoolIcon from "@material-ui/icons/Pool";
import DeckIcon from "@material-ui/icons/Deck";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import React from "react";

import RajAkshay from "../../images/onGoingProjects/RajAkshay.jpg";
import RajAkshayPDF from "../../assets/pdf/RajAkshay.pdf";
import EC_Raj_Akshay from "../../assets/pdf/1_EC_Raj_Akshay.pdf";
import Env_Audit_Report_Raj_Akshay from "../../assets/pdf/2_Env_Audit_Report_Raj_Akshay.pdf";
import Paper_Notice_Raj_Akshay from "../../assets/pdf/3_Paper_Notice_Raj_Akshay.pdf";
import EntranceLobby from "../../images/projectDetails/RajAkshay/EntranceLobby.jpg";
import ClubHouse from "../../images/projectDetails/RajAkshay/ClubHouse.jpg";
import FullView1 from "../../images/projectDetails/RajAkshay/FullView1.jpg";
import FullView2 from "../../images/projectDetails/RajAkshay/FullView2.jpg";
import FullView3 from "../../images/projectDetails/RajAkshay/FullView3.jpg";
import FullView4 from "../../images/projectDetails/RajAkshay/FullView4.jpg";
import TopLayout from "../../images/projectDetails/RajAkshay/TopLayout.jpg";
import FloorPlan from "../../images/floorPlans/RajAkshay.jpg";
import Barcode from "../../images/barcodes/RajAkshay.jpg";

export const heroImage = {
	imageUrl: RajAkshay,
	pdfUrl: RajAkshayPDF,
	propertyName: "Raj Akshay",
};

export const locationDetails = {
	name: "Raj Akshay",
	lat: "19.2738773",
	lng: "72.8784825",
	googleMapUrl:
		"https://www.google.co.in/maps/place/Raj+Akshay/@19.2738773,72.8784825,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b1c519c1cbc5:0xcb6585f37ae25f13!8m2!3d19.2738722!4d72.8806712",
};

export const photos = [
	{
		src: Barcode,
		width: 4,
		height: 4,
		title: "Raj Akshay",
	},
	{
		src: EntranceLobby,
		width: 4,
		height: 3,
		title: "Entrance Lobby",
	},
	{
		src: ClubHouse,
		width: 4,
		height: 3,
		title: "Club House",
	},
	{
		src: FullView1,
		width: 4,
		height: 3,
		title: "Full View",
	},
	{
		src: FullView2,
		width: 4,
		height: 3,
		title: "Full View",
	},
	{
		src: FullView3,
		width: 4,
		height: 3,
		title: "Full View",
	},
	{
		src: FullView4,
		width: 4,
		height: 3,
		title: "Full View",
	},
	{
		src: TopLayout,
		width: 4,
		height: 4,
		title: "Top Layout",
	},
];

export const prices = [
	{
		topology: "1BHK",
		price: "51.33 Lakh++",
		status: "Possession Date- May 2022",
	},
	{
		topology: "2BHK",
		price: "80.7 Lakh++",
		status: "Possession Date- May 2022",
	},
];

export const amenities = [
	{
		icon: <HouseIcon fontSize='large' />,
		name: "ClubHouse",
	},
	{
		icon: <PoolIcon fontSize='large' />,
		name: "Swimming Pool",
	},
	{
		icon: <DeckIcon fontSize='large' />,
		name: "Garden",
	},
	{
		icon: <FitnessCenterIcon fontSize='large' />,
		name: "Air Conditioned Gym",
	},
	{
		icon: <HomeWorkIcon fontSize='large' />,
		name: "Multipurpose Hall",
	},
];

export const propertyOverview = {
	propertyName: "Raj Akshay",
	overview:
		"Where True Brilliance Shines through... \nDesigned especially for the discerning and privileged few, Raj Akshay is the perfect example of how form and function compliment each other to accommodate everything essential to an enriching way of life. Located at 5 minutes walking distance from Mira Bhayander Road in Mira Road, Raj Akshay offers an excellent choice of well designed 1 & 2 BHK flats outfitted with the best of contemporary in-house amenities and well planned outdoor facilities namely Landscaped garden, Swimming pool, Club house with Gymnasium etc.",
	facilities:
		"Premium quality flooring.\nPremium quality wall tiles in all bathrooms and kitchen.\nGranite kitchen platform with S.S. Sink.\nBranded fittings in all bathrooms.\nConcealed copper wiring with branded electrical fitting\nAnodised / Powder Coated Aluminium sliding windows with mosquito net.\nProvision for cable TV and Telephone connection.",
	maharerano: "P51700019990",
	topology: "Topology: 1 & 2 BHK Apartments",
	status: "Status: Under Construction",
	ecInfoPDF : [
		EC_Raj_Akshay,
		Env_Audit_Report_Raj_Akshay,
		Paper_Notice_Raj_Akshay,
	],
};

export const floorplan = FloorPlan;
