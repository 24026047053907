import TwentyYears1 from '../images/homePageSlider/20Years_1.jpg';
import TwentyYears2 from '../images/homePageSlider/20Years_2.jpg';
import TwentyYears3 from '../images/homePageSlider/20Years_3.jpg';

export const slides = [
	{
		title: 'Contact Us',
		image: TwentyYears1,
		url: '/contact-us'
	},
	{
		title: 'Contact Us',
		image: TwentyYears2,
		url: '/contact-us'
	},
	{
		title: 'Contact Us',
		image: TwentyYears3,
		url: '/contact-us'
	}
];
