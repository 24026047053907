import React from "react";
import RajHorizon from "../../images/projectDetails/RajHorizon/RajHorizon.jpg";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SchoolIcon from "@material-ui/icons/School";
import PoolIcon from "@material-ui/icons/Pool";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";

export const heroImage = {
	imageUrl:RajHorizon,
	pdfUrl:""
}

export const locationDetails = {
	name: "Raj Horizon",
	lat: "19.2946113",
	lng: "72.8649409",
	googleMapUrl:
		"https://www.google.com/maps/place/Raj+Horizon/@19.2946113,72.8649409,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b0391aa39977:0xd6b8e3ab8644fc91!8m2!3d19.2946113!4d72.8671296?hl=en-GB",
};

export const photos = [
	{
		src: RajHorizon,
		width: 4,
		height: 3,
		title: "Full View",
	},
];

export const amenities = [
	{
		icon: <SchoolIcon fontSize='large' />,
		name: "Schools Nearby",
	},
	{
		icon: <DirectionsBusIcon fontSize='large' />,
		name: "Bus-stops Nearby",
	},
	{
		icon: <ShoppingCartIcon fontSize='large' />,
		name: "Markets Nearby",
	},
	{
		icon: <FitnessCenterIcon fontSize='large' />,
		name: "Gymnasium",
	},
	{
		icon: <PoolIcon fontSize='large' />,
		name: "Swimming Pool",
	},
];

export const propertyOverview = {
	propertyName: "Raj Horizon",
	overview:
		"Check out this residential project for sale by Raj Realty in Mira Road East, Mira Bhayandar. Raj Horizon offers Apartment as property type. It is currently Ready to Move. Available configurations include 2.5 BHK, 3 BHK. As per the area plan, units are in the size range of 890.0 - 1210.0 sq.ft.. Raj Horizon was launched in June 2010. Raj Horizon possession is Apr, 2012. Altogether, there are 128 units available. There is 1 building in this property. The address of Raj Horizon is Mira Road East.At Raj Horizon, all residents have easy access to some of the truly desired facilities such as Lift, Rainwater Harvesting, Gymnasium, Power Backup. If you are a sports lover, there are provisions meant for you such as a Cycling & Jogging Track. There is 24x7 Security. Come home to Raj Horizon.",
	facilities:
		"Premium quality flooring.\nPremium quality wall tiles in all bathrooms and kitchen.\nGranite kitchen platform with S.S. Sink.\nBranded fittings in all bathrooms.\nConcealed copper wiring with branded electrical fitting\nAnodised / Powder Coated Aluminium sliding windows with mosquito net.\nProvision for cable TV and Telephone connection.",
	maharerano: "P51700019990",
};
