import React from "react";
import Navbar from "./Navbar/Navbar";

class Header extends React.Component {
	state = {
		navbarOpen: false,
	};

	handleNavbar = () => {
		this.setState({ navbarOpen: !this.state.navbarOpen });
	};

	render() {
		return (
			<Navbar
				navbarState={this.state.navbarOpen}
				handleNavbar={this.handleNavbar}
			/>
		);
	}
}

export default Header;
