import "./navbar.scss";
import LoginButton from "../LoginButton/Button";
import Brand from "./Brand";
import BurgerMenu from "./BurgerMenu";
import CollapseMenu from "./CollapseMenu";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSpring, animated, config } from "react-spring";
import { Link } from "react-router-dom";

const Navbar = (props) => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setanchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    //console.log("event is", event);
    setIsOpen(true);
    setanchorEl(event.currentTarget.id);
  };
  const handleMouseLeave = (event) => {
    setIsOpen(false);
    setanchorEl(null);
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  let x = ["navbar"];
  if (scrolled) {
    x.push("scrolled");
  }

  const barAnimation = useSpring({
    from: { transform: "translate3d(0, -10rem, 0)" },
    transform: "translate3d(0, 0, 0)",
  });

  const linkAnimation = useSpring({
    from: { transform: "translate3d(0, 30px, 0)", opacity: 0 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 800,
    config: config.wobbly,
  });

  return (
    <>
      <NavBar className={x.join(" ")} style={barAnimation}>
        <Link to="/" onMouseEnter={handleMouseEnter}>
          <span className="headerlogo">
            <Brand />
          </span>
        </Link>

        <FlexContainer>
          <NavLinks style={linkAnimation} onMouseLeave={handleMouseLeave}>
            <Link to="/" id="Home" onMouseEnter={handleMouseEnter}>
              HOME
              <KeyboardArrowDownIcon viewBox="0 0 24 13" />
            </Link>
            <div
              className={`${
                isOpen && anchorEl === "Home" ? "is-open" : ""
              } topmenu`}
            >
              <div
                className={`${
                  isOpen && anchorEl === "Home" ? "is-open" : ""
                } mn`}
              >
                <div className="dv">
                  <Link to="#" className="main">
                    WHO WE ARE
                  </Link>
                  <div className="clear">
                    <Link to="/about-us">
                      <ChevronRightIcon fontSize="small" viewBox="0 -5 24 24" />
                      About Us
                    </Link>
                    <Link to="/about-us">
                      <ChevronRightIcon fontSize="small" viewBox="0 -5 24 24" />
                      Vision And Mission
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <Link
              to="/currentprojects"
              id="about"
              onMouseEnter={handleMouseEnter}
            >
              OUR PROJECTS
              <KeyboardArrowDownIcon viewBox="0 0 24 13" />
            </Link>
            <div
              className={`${
                isOpen && anchorEl === "about" ? "is-open" : ""
              } topmenu`}
            >
              <div className="ct">
                <div
                  className={`${
                    isOpen && anchorEl === "about" ? "is-open" : ""
                  } mn`}
                >
                  <div className="dv">
                    <Link to="#" className="main">
                      WHAT WE DO
                    </Link>
                    <div className="clear">
                      <Link to="/currentprojects">
                        <ChevronRightIcon
                          fontSize="small"
                          viewBox="0 -5 24 24"
                        />
                        Current Projects
                      </Link>
                      <Link to="/completedprojects">
                        <ChevronRightIcon
                          fontSize="small"
                          viewBox="0 -5 24 24"
                        />
                        Completed Projects
                      </Link>
                      <Link to="/allprojects">
                        <ChevronRightIcon
                          fontSize="small"
                          viewBox="0 -5 24 24"
                        />
                        All Projects
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Link to="/contact-us" id="contact">
              CONTACT US
            </Link>
          </NavLinks>
          {/* <LoginButton /> */}
          <BurgerWrapper>
            <BurgerMenu
              navbarState={props.navbarState}
              handleNavbar={props.handleNavbar}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>

      <CollapseMenu
        navbarState={props.navbarState}
        handleNavbar={props.handleNavbar}
      />
    </>
  );
};

export default Navbar;

const NavBar = styled(animated.nav)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: white;

  z-index: 99;
  font-size: 16px;

  @media (max-width: 1024px) {
    background: #fff;
  }
`;

const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto 20px;
  justify-content: space-between;
  height: 4rem;
`;

const NavLinks = styled(animated.ul)`
  list-style-type: none;
  margin: auto 0;
  padding-inline-start: 0;

  & a {
    color: black;
    padding: 28px 15px 19px 0;
    border-bottom: 1px solid transparent;
    margin: 0 0.7rem;
    transition: all 0.9s ease;
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
    &:hover {
      font-weight: 700;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: 1025px) {
    display: none;
  }
`;
