import RajHeritage1 from "../../images/onGoingProjects/RajHeritage1.jpg";
import RajHeritage1PDF from "../../assets/pdf/RajHeritage1.pdf";
import HouseIcon from "@material-ui/icons/House";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import PoolIcon from "@material-ui/icons/Pool";
import DeckIcon from "@material-ui/icons/Deck";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import VideocamIcon from "@material-ui/icons/Videocam";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";

import React from "react";

import EntranceLobby from "../../images/projectDetails/RajHeritage1/Lobby.jpg";
import LivingRoom from "../../images/projectDetails/RajHeritage1/LivingRoom.jpg";
import MasterBedroom from "../../images/projectDetails/RajHeritage1/MasterBedroom.jpg";
import CommanBedroom from "../../images/projectDetails/RajHeritage1/CommanBedroom.jpg";
import Kitchen from "../../images/projectDetails/RajHeritage1/Kitchen.jpg";
import Bathroom from "../../images/projectDetails/RajHeritage1/Bathroom.jpg";
import FullView from "../../images/projectDetails/RajHeritage1/FullView.jpg";
import FullViewNight from "../../images/projectDetails/RajHeritage1/FullViewNight.jpg";
import TopLayoutView from "../../images/projectDetails/RajHeritage1/TopLayoutView.jpg";
import FloorPlan from "../../images/floorPlans/RajHeritage1.jpg";

export const heroImage = {
	imageUrl: RajHeritage1,
	pdfUrl: RajHeritage1PDF,
	propertyName: "RajFlorenza",
};

export const locationDetails = {
	name: "Raj Heritage",
	lat: "19.2922306",
	lng: "72.8652896",
	googleMapUrl:
		"https://www.google.co.in/maps/place/Raj+Heritage+Mira+Road+East/@19.2922306,72.8652896,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b1638fba7287:0x92a165a90f69943f!8m2!3d19.2922255!4d72.8674783",
};

export const photos = [
	{
		src: EntranceLobby,
		width: 5,
		height: 3,
		title: "Entrance Lobby",
	},
	{
		src: LivingRoom,
		width: 4,
		height: 3,
		title: "Living Room",
	},
	{
		src: MasterBedroom,
		width: 4,
		height: 3,
		title: "Master Bedroom",
	},
	{
		src: CommanBedroom,
		width: 4,
		height: 3,
		title: "Comman Bedroom",
	},
	{
		src: Kitchen,
		width: 3,
		height: 3,
		title: "Kitchen",
	},
	{
		src: Bathroom,
		width: 3,
		height: 3,
		title: "Bathroom",
	},
	{
		src: FullView,
		width: 4,
		height: 3,
		title: "Full View",
	},
	{
		src: FullViewNight,
		width: 4,
		height: 3,
		title: "Full View Night",
	},
	{
		src: TopLayoutView,
		width: 4,
		height: 3,
		title: "Top Layout View",
	},
];

export const prices = [
	{
		topology: "1BHK",
		price: "61.23 Lakh++",
		status: "Possession Date- December 2021",
	},
	{
		topology: "2BHK",
		price: "89.66 Lakh++",
		status: "Possession Date- December 2021",
	},
];

export const amenities = [
	{
		icon: <HouseIcon fontSize='large' />,
		name: "ClubHouse",
	},
	{
		icon: <PoolIcon fontSize='large' />,
		name: "Swimming Pool",
	},
	{
		icon: <DeckIcon fontSize='large' />,
		name: "Garden",
	},
	{
		icon: <FitnessCenterIcon fontSize='large' />,
		name: "Well Equipped Gym",
	},
	{
		icon: <MeetingRoomIcon fontSize='large' />,
		name: "Video door camera",
	},
	{
		icon: <PhoneInTalkIcon fontSize='large' />,
		name: "Intercom facility",
	},
	{
		icon: <VideocamIcon fontSize='large' />,
		name: "CCTV Camera",
	},
];

export const propertyOverview = {
	propertyName: "Raj Heritage 1",
	overview:
		"Celebrate your arrival at Raj Heritage 1, a unique brand of lifestyle in Mira Road (E). With resounding architecture, well defined designs and an intricate layout plan, Raj Heritage 1 is a doorway to lavish living. Designed to make a difference in the way you view life, Raj Heritage 1 offer spacious 1 & 2 BHK apartments that come adorned with the choices of extreme luxury, comfort and freedom. With maximum utilization of space, Modern amenities and facilities like Swimming pool, Gymnasium, Children's play area etc., Raj Heritage 1 makes your life far from the ordinary.",
	facilities:
		"Premium quality flooring.\nPremium quality wall tiles in all bathrooms and kitchen.\nGranite kitchen platform with S.S. Sink.\nBranded fittings in all bathrooms.\nConcealed copper wiring with branded electrical fitting.\nAnodised/Powder coated aluminium sliding windows with mosquito net.\nProvision for cable T.V and Telephone connection.",
	maharerano: "P51700020259",
	topology: "Topology: 1 & 2 BHK Apartments",
	status: "Status: Under Construction",
};

export const floorplan = FloorPlan;
