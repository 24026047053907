import React from 'react';
import $ from 'jquery';
import { makeStyles } from '@material-ui/core/styles';
import './Counter.scss';
import Grid from '@material-ui/core/Grid';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ApartmentIcon from '@material-ui/icons/Apartment';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import AddIcon from '@material-ui/icons/Add';

class Counter extends React.Component {
	componentDidMount() {
		var a = 0;
		$(window).scroll(function() {
			if ($('#counter').offset()) {
				var oTop = $('#counter').offset().top - window.innerHeight;
			}

			if (a === 0 && $(window).scrollTop() > oTop) {
				$('.counter-value').each(function() {
					var $this = $(this),
						countTo = $this.attr('data-count');

					$({
						countNum: $this.text()
					}).animate(
						{
							countNum: countTo
						},
						{
							duration: 2000,
							easing: 'swing',
							step: function() {
								$this.text(Math.floor(this.countNum));
							},
							complete: function() {
								$this.text(this.countNum);
								//alert('finished');
							}
						}
					);
				});
				a = 1;
			}
		});
	}

	render() {
		return (
			<div className="fake-div">
				<div id="counter">
					<Grid container spacing={3}>
						<Grid item xs={6} sm={3}>
							<div className="unit">
								<ApartmentIcon className="icon" fontSize="large" />
								<div>
									<span className="counter-value" data-count="30">
										0
									</span>
									<AddIcon className="plus-icon" fontSize="small" />
								</div>
								<h4>Projects Completed</h4>
							</div>
						</Grid>
						<Grid item xs={6} sm={3}>
							<div className="unit">
								<EmojiPeopleIcon className="icon" fontSize="large" />
								<div>
									<span className="counter-value" data-count="5000">
										100
									</span>
									<AddIcon className="plus-icon" fontSize="small" />
								</div>

								<h4>Happy Families</h4>
							</div>
						</Grid>
						<Grid item xs={6} sm={3}>
							<div className="unit">
								<PeopleAltOutlinedIcon className="icon" fontSize="large" />
								<div>
									<span className="counter-value" data-count="50">
										2
									</span>
									<AddIcon className="plus-icon" fontSize="small" />
								</div>
								<h4>Team Member</h4>
							</div>
						</Grid>
						<Grid item xs={6} sm={3}>
							<div className="unit">
								<MeetingRoomIcon className="icon" fontSize="large" />
								<div>
									<span className="counter-value" data-count="2700000">
										10000
									</span>
									<AddIcon className="plus-icon" fontSize="small" />
								</div>
								<h4>Sq. Ft Area Delivered</h4>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		);
	}
}
export default Counter;
