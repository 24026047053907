import "./ArrowAnimation.css";
import React from "react";

const ArrowAnimation = () => {
	return (
		<div id='arrowAnim'>
			<div className='arrowSliding'>
				<div className='arrow' />
			</div>
			<div className='arrowSliding delay1'>
				<div className='arrow' />
			</div>
		</div>
	);
};

export default ArrowAnimation;
