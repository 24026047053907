import React from "react";
import RajAtlantis from "../../images/projectDetails/RajAtlantis/RajAtlantis.jpg";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SchoolIcon from "@material-ui/icons/School";
import PoolIcon from "@material-ui/icons/Pool";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";

export const heroImage = {
	imageUrl:RajAtlantis,
	pdfUrl:""
}

export const locationDetails = {
	name: "Raj Atlantis",
	lat: "19.2916841",
	lng: "72.8684991",
	googleMapUrl:
		"https://www.google.com/maps/place/Raj+Atlantis+Apartments/@19.2916841,72.8684991,17z/data=!4m5!3m4!1s0x3be7b038bc8de4a1:0x5d11eee87c599ee2!8m2!3d19.2916841!4d72.8684991",
};

export const photos = [
	{
		src: RajAtlantis,
		width: 8,
		height: 3,
		title: "Full View",
	},
];

export const amenities = [
	{
		icon: <SchoolIcon fontSize='large' />,
		name: "Schools Nearby",
	},
	{
		icon: <DirectionsBusIcon fontSize='large' />,
		name: "Bus-stops Nearby",
	},
	{
		icon: <ShoppingCartIcon fontSize='large' />,
		name: "Markets Nearby",
	},
	{
		icon: <FitnessCenterIcon fontSize='large' />,
		name: "Gymnasium",
	},
	{
		icon: <PoolIcon fontSize='large' />,
		name: "Swimming Pool",
	},
];

export const propertyOverview = {
	propertyName: "Raj Atlantis",
	overview:
		"Developed by Raj Realty, Raj Atlantis brings to you an exclusive project located at a popular area, Mira Road in Mumbai. The project is well equipped with all the modern amenities and features for a comfortable life with your family. Both the exterior and the interior of the architecture is intelligently designed making optimum utilization of space. The airy apartments have been uniquely designed with sufficient amount of natural light that follows into each of the rooms. This is an investment you will be truly proud of. Move into Raj Atlantis by Raj Realty at Mira Road in Mumbai for a blissful experience of a lifetime.",
	facilities:
		"Premium quality flooring.\nPremium quality wall tiles in all bathrooms and kitchen.\nGranite kitchen platform with S.S. Sink.\nBranded fittings in all bathrooms.\nConcealed copper wiring with branded electrical fitting\nAnodised / Powder Coated Aluminium sliding windows with mosquito net.\nProvision for cable TV and Telephone connection.",
	maharerano: "P51700019990",
};
