import "../Common.scss";
import React, { Component } from "react";
import ImageGallery from "../Imagegallary";
import Map from "../LocationMap";
import {
	heroImage,
	photos,
	locationDetails,
	amenities,
	propertyOverview,
	floorplan,
} from "../../../../Data/Projects/RajRudraksha";

import PropertyOverview from "../PropertyOverview";
import Amenities from "../Amenities";
import FloorPlan from "../FloorPlan";
import HeroImage from "../HeroImage";
import { Container } from "@material-ui/core";

export class RajRudraksha extends Component {
	render() {
		return (
			<div className='project-detail'>
				<HeroImage heroImage={heroImage} />
				<Container>
					<PropertyOverview overview={propertyOverview} />
					<ImageGallery photos={photos} />
					<Amenities amenities={amenities} />
					<FloorPlan floorplan={floorplan} />
				</Container>
				<Map
					lat={locationDetails.lat}
					lng={locationDetails.lng}
					name={locationDetails.name}
					googleMapUrl={locationDetails.googleMapUrl}
				/>
			</div>
		);
	}
}

export default RajRudraksha;
