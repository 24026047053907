import './Common.scss';
import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import {Typography} from '@material-ui/core';

export const ImageGallery = (props) => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);

	const openLightbox = useCallback((event, { photo, index }) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	const closeLightbox = () => {
		setCurrentImage(0);
		setViewerIsOpen(false);
	};

	return (
		<>
		<div className="outer-header-div">
             <div className="section-title-block">
              
              <Typography variant="h5" align="center">
                Gallery
               </Typography>
               <div className="slide-border">
               <div className="before"></div>
               <hr className="hr"/>
               <div className="after"></div>
               </div>
             </div>
		</div>
		<div className='image-gallery'>
			
			<Gallery photos={props.photos} onClick={openLightbox} />
			<ModalGateway>
				{viewerIsOpen ? (
					<Modal onClose={closeLightbox}>
						<Carousel
							currentIndex={currentImage}
							views={props.photos.map((x) => ({
								...x,
								srcset: x.srcSet,
								caption: x.title,
							}))}
						></Carousel>
					</Modal>
				) : null}
			</ModalGateway>
		</div>
	</>
	);
};
export default ImageGallery;
