import React from "react";
import contactus from "../../assets/contactus.jpg";
import "./ContactUs.scss";
import PhoneInTalkRoundedIcon from "@material-ui/icons/PhoneInTalkRounded";
import ContactPhoneRoundedIcon from "@material-ui/icons/ContactPhoneRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import LocationMap from "../ProjectListing/Projects/LocationMap";
import DirectionsIcon from "@material-ui/icons/Directions";
import EnquiryComponent from "../FeaturedProjects/EnquiryComponent";
import { Grid, makeStyles, Container } from "@material-ui/core";
import { ButtonContainedSecondary } from "../Common/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
const ContactUs = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <img src={contactus} alt="My team" className="contact-img" />
      <div className="hero-img-text">
        <h1>CONTACT US</h1>
        <hr />
        <h2>
          Questions to ask? Are you looking for your Dream House?
          <br /> Here's how you can reach us.
        </h2>
      </div>
      <Container>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={4}>
              <ul className="quick-links">
                <h3>SALES </h3>
                <div style={{ display: "inline-flex" }}>
                  <li>
                    <PhoneInTalkRoundedIcon />
                  </li>
                  <div>
                    <div className="icon-text">
                      <a href="tel:7400001748">+91 7400001748</a>
                    </div>
                    <div className="icon-text">
                      <a href="tel:7400001749">+91 7400001749</a>
                    </div>
                  </div>
                </div>
                <h3>CORPORATE OFFICE</h3>
                <div style={{ display: "inline-flex" }}>
                  <li className="second-li">
                    <ContactPhoneRoundedIcon />
                  </li>
                  <div>
                    <div className="icon-text">
                      <a href="tel:022 2855 4700">022 2855 4700</a>
                    </div>
                    <div className="icon-text">
                      <a href="tel:022 2855 4701"> 022 2855 4701</a>
                    </div>
                    <div className="icon-text">
                      <a href="tel:022 2855 4703"> 022 2855 4703</a>
                    </div>
                  </div>
                </div>
                <h3>EMAIL US</h3>
                <div style={{ display: "inline-flex" }}>
                  <li>
                    <EmailRoundedIcon />
                  </li>
                  <div className="enquiry-text">
                    <a href="mailto:sales@rajrealtygroup.com">
                      sales@rajrealtygroup.com
                    </a>
                  </div>
                </div>
                <h3>FEEDBACK PAGE</h3>
                <Link to="/survey-page/" style={{ textDecoration: "none" }}>
                  <ButtonContainedSecondary>Feedback</ButtonContainedSecondary>
                </Link>
              </ul>
            </Grid>

            <Grid sm={12} lg={8} className="grid-item">
              <div className="info">
                <h3>Corporate Office Location</h3>
                <hr />
                <p>
                  Raj Florenza, Vijay Park, Near Amber Plaza Hall, OPP. MBMC
                  Garden, Mira Road(E), 401 107
                </p>
                <a
                  target="_blank"
                  href="https://www.google.co.in/maps/place/Raj+Realty/@19.2058299,72.7547997,12z/data=!4m8!1m2!2m1!1sraj+realty+!3m4!1s0x3be7b04ef51677cd:0xa7d56d5199fc87f!8m2!3d19.278635!4d72.8734209"
                >
                  <span>
                    <DirectionsIcon viewBox="0 -2 24 24" />
                    DIRECTIONS
                  </span>
                </a>
              </div>

              <div className="info">
                <h3>General Enquiry</h3>
                <hr style={{ width: "60px" }} />
                <p>
                  Is there anything else we can help you with? You can reach out
                  to us and register your interest.
                </p>
                <a onClick={handleOpen} style={{ cursor: "pointer" }}>
                  <ContactSupportIcon viewBox="0 -4 24 24" />
                  <span>GET IN TOUCH</span>
                </a>
                <EnquiryComponent openModal={open} closeModal={handleClose} />
              </div>

              <div className="location-info">
                <LocationMap
                  name="Raj Realty"
                  lat="19.2056512"
                  lng="72.8160973"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};
export default ContactUs;
