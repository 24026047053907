import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { Theme, useMediaQuery } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const stylePrimary: (theme: Theme) => any = (theme) => {
	return {
		root: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.primary.dark,
				color: "#fff",
			},
			"&:disabled": {
				backgroundColor: theme.palette.error.light,
			},
		},
	};
};

const styleSecondary: (theme: Theme) => any = (theme) => {
	return {
		root: {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.secondary.contrastText,
				color: theme.palette.primary.contrastText,
			},
			"&:disabled": {
				backgroundColor: theme.palette.error.light,
			},
		},
	};
};

export const ButtonContainedPrimary = withStyles(stylePrimary)(
	(props: ButtonProps) => {
		const { className } = props;
		const classes = props.classes || {};
		const isSmallScreen = useMediaQuery((theme) =>
			theme.breakpoints.down("xs")
		);
		const buttonProps = {
			size: isSmallScreen ? "small" : "medium",
		};
		return (
			<Button
				{...props}
				{...buttonProps}
				className={`${className} ${classes.root}`}
				variant='contained'
			/>
		);
	}
);

export const ButtonContainedSecondary = withStyles(styleSecondary)(
	(props: ButtonProps) => {
		const { className } = props;
		const classes = props.classes || {};
		const isSmallScreen = useMediaQuery((theme) =>
			theme.breakpoints.down("xs")
		);
		const buttonProps = {
			size: isSmallScreen ? "small" : "medium",
		};
		return (
			<Button
				{...props}
				{...buttonProps}
				className={`${className} ${classes.root}`}
				variant='outlined'
			/>
		);
	}
);
