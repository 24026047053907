import React from "react";
import DayView from "../../images/projectDetails/RajLifestyle/DayView.jpg";
import NightView from "../../images/projectDetails/RajLifestyle/NightView.jpg";
import Lobby from "../../images/projectDetails/RajLifestyle/Lobby.jpg";
import FullView from "../../images/projectDetails/RajLifestyle/FullView.jpg";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SchoolIcon from "@material-ui/icons/School";
import PoolIcon from "@material-ui/icons/Pool";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import RajLifestyle from "../../images/projectDetails/RajLifestyle/NightView.jpg";

export const heroImage = {
	imageUrl:RajLifestyle,
	pdfUrl:""
}

export const locationDetails = {
	name: "Raj Lifestyle",
	lat: "19.282786",
	lng: "72.8771435",
	googleMapUrl:
		"https://www.google.com/maps/place/Raj+Lifestyle,+Shanti+Vidya+Nagri+Rd,+Hatkesh+Udhog+Nagar,+Mira+Road,+Mira+Bhayandar,+Maharashtra+401107/@19.282786,72.8771435,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b068e88406b3:0x1a9c187d00b6380d!8m2!3d19.282786!4d72.8793322",
};

export const photos = [
	{
		src: NightView,
		width: 4,
		height: 3,
		title: "Night View",
	},
	{
		src: DayView,
		width: 4,
		height: 3,
		title: "Day View",
	},
	{
		src: Lobby,
		width: 4,
		height: 3,
		title: "Lobby",
	},
	{
		src: FullView,
		width: 4,
		height: 3,
		title: "Full View",
	},
];

export const amenities = [
	{
		icon: <SchoolIcon fontSize='large' />,
		name: "Schools Nearby",
	},
	{
		icon: <DirectionsBusIcon fontSize='large' />,
		name: "Bus-stops Nearby",
	},
	{
		icon: <ShoppingCartIcon fontSize='large' />,
		name: "Markets Nearby",
	},
	{
		icon: <FitnessCenterIcon fontSize='large' />,
		name: "Gymnasium",
	},
	{
		icon: <PoolIcon fontSize='large' />,
		name: "Swimming Pool",
	},
];

export const propertyOverview = {
	propertyName: "Raj Lifestyle",
	overview:
		"A project that gives you a chance to live the life of your imagination is a place called Raj Lifestyle. The project is built with exceptional features that make your life complete and satisfied. The designing and the crafting of the project is an art of work which showcases elegance and perfection. The modern day facilities are wrapped with all luxuries making for a world of simplicity. The smart spacing and well equipped floor plan is an intelligent work piece. The interiors and the exteriors of the project are keenly crafted keeping in mind the attractiveness and lavish lifestyle behavior of the resident. The basic concept of the project is to offer comforts and conveniences. The location and the surrounding is also beauty at its best.",
	facilities:
		"Premium quality flooring.\nPremium quality wall tiles in all bathrooms and kitchen.\nGranite kitchen platform with S.S. Sink.\nBranded fittings in all bathrooms.\nConcealed copper wiring with branded electrical fitting\nAnodised / Powder Coated Aluminium sliding windows with mosquito net.\nProvision for cable TV and Telephone connection.",
	maharerano: "P51700019990",
};
