import React from "react";
import history from "../../history";
import ShareIcon from "../ShareIcons/ShareIcons";
import {
	Card,
	CardContent,
	CardActions,
	CardActionArea,
	CardHeader,
	Typography,
	Button,
} from "@material-ui/core";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import MoreIcon from "@material-ui/icons/More";
import ShareRoundedIcon from "@material-ui/icons/ShareRounded";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import EnquiryComponent from "../FeaturedProjects/EnquiryComponent";
import "./CardComponent.scss";

const useStylesAmenities = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.common.grey,
	},
	tooltip: {
		backgroundColor: theme.palette.common.grey,
		color: theme.palette.common.red,
	},
}));

const useStyles = makeStyles((theme) => ({
	paper: {
		backgroundColor: "lightgrey",
		padding: theme.spacing(1, 1, 1),
	},
}));

function AmenitiesTooltip(props) {
	const classes = useStylesAmenities();

	return <Tooltip arrow classes={classes} {...props} />;
}

const CardComponent = (props) => {
	const {
		title,
		shareDescription,
		url,
		location,
		status,
		description,
		imgSrc,
		amenities,
	} = props;

	const getAmenities = amenities.map((single) => {
		const { icon, title } = single;
		return (
			<>
				<AmenitiesTooltip title={title}>{icon}</AmenitiesTooltip>
			</>
		);
	});

	const [open, setOpen] = React.useState(false);
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = (event) => {
		setOpen(false);
		setAnchorEl(event.currentTarget);
	};

	const handleCloseAnchorEl = () => {
		setAnchorEl(null);
	}

	const handleKnowMoreOpen = (event) => {
		history.push(url);
	}

	const openAnchor = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	return (
		<Card
			style={{ maxWidth: "100%", borderRadius: "16px" }}
			className='card-shadow'
		>
			<CardHeader
				className='cardHeader'
				titleTypographyProps={{ variant: "h6", align: "center" }}
				title={title}
			></CardHeader>
			<EnquiryComponent openModal={open} closeModal={handleClose} />
			<CardActionArea>
				<div className='zoom-effect-container'>
					<div className='image-card'>
						<img
							src={imgSrc}
							style={{ height: "200px", width: "100%" }}
							onClick={() => {
								
							}}
						/>
					</div>
				</div>
				<CardContent className='card-content'>
					<Typography variant='subtitle2' gutterBottom>
						<LocationOnOutlinedIcon fontSize='small' viewBox='5 -3 24 24' className="location"/>
						{location}

						<ShareRoundedIcon
							onClick={handleOpen}
							aria-describedby={id}
							className='share-icon'
						/>
						<Popover
							id={id}
							open={openAnchor}
							anchorEl={anchorEl}
							onClose={handleCloseAnchorEl}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
						>
							<div className={classes.paper}>
								<ShareIcon url={url} shareDescription={shareDescription} />
							</div>
						</Popover>
					</Typography>
					<div className='prop-description'>
						<Typography variant='body2' component='p'>
							{status}
						</Typography>
					</div>
					<Typography variant='body2' component='p'>
						{description}
					</Typography>

					<div
						style={{
							marginTop: "1.25rem",
							marginBottom: "0.7rem",
							textAlign: "center",
						}}
					>
						<Button
							className='btn-oncard'
							endIcon={<MoreIcon />}
							style={{ borderRadius: "10px" }}
							variant='outlined'
							onClick={handleKnowMoreOpen}
						>
							Know more
						</Button>
					</div>
				</CardContent>
			</CardActionArea>
			<CardActions className='card-action-div'>
				<Typography variant='caption' color='textSecondary'>
					{getAmenities}
				</Typography>
			</CardActions>
		</Card>
	);
};
export default CardComponent;
