import "../ProjectListing/CardComponent.scss";
import React from "react";
import {
	FacebookShareButton,
	WhatsappShareButton,
	EmailShareButton,
	FacebookIcon,
	
	WhatsappIcon,
	EmailIcon,
} from "react-share";

let initialUrl="https://www.rajrealtygroup.com";
const ShareIcons = (props) => {
	const { url, shareDescription } = props;
	
	return (
		<div className='social-media'>
			<FacebookShareButton
				url={`${initialUrl}${url}`}
				quote={shareDescription}
				className='social-media-icons'
			>
				<FacebookIcon size={28} round />
			</FacebookShareButton>
		
			<WhatsappShareButton
				url={`${initialUrl}${url}`}
				title={shareDescription}
				className='social-media-icons'
			>
				<WhatsappIcon size={28} round />
			</WhatsappShareButton>
			<EmailShareButton
				subject={`${initialUrl}${url}`}
				body={shareDescription}
				className='social-media-icons'
			>
				<EmailIcon size={28} round />
			</EmailShareButton>
		</div>
	);
};
export default ShareIcons;
