import React from "react";
import styled from "styled-components";

const Burgermenu = (props) => {
	return (
		<Wrapper onClick={props.handleNavbar}>
			<div className={props.navbarState ? "open" : ""}>
				<span>&nbsp;</span>
				<span>&nbsp;</span>
				<span>&nbsp;</span>
			</div>
		</Wrapper>
	);
};

export default Burgermenu;

const Wrapper = styled.div`
	position: fixed;
	padding-top: 0.9rem;
	cursor: pointer;
	display: block;
	left: 15px;
	top: 5px;
	& span {
		background: gray;
		display: block;
		position: relative;
		width: 30px;
		height: 0.2rem;
		margin-bottom: 0.5rem;
		transition: all ease-in-out 0.4s;
	}

	.open span:nth-child(2) {
		opacity: 0;
	}

	.open span:nth-child(3) {
		transform: rotate(45deg);
		top: -15px;
	}

	.open span:nth-child(1) {
		transform: rotate(-45deg);
		top: 8px;
	}
`;
