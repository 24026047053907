import "./Common.scss";
import React from "react";
import { Typography } from "@material-ui/core";
import { GlassMagnifier } from "react-image-magnifiers";

const FloorPlan = (props) => {
	return (
		<>
			<div className='outer-header-div'>
				<div className='section-title-block'>
					<Typography variant='h5' align='center'>
						Floor Plan
					</Typography>
					<div className='slide-border'>
						<div className='before'></div>
						<hr className='hr' />
						<div className='after'></div>
					</div>
				</div>
			</div>
			<div>
				<GlassMagnifier
					imageSrc={props.floorplan}
					imageAlt='floorPlan'
					square={true}
					magnifierSize='30%'
					magnifierBorderSize={3}
					magnifierBorderColor='rgba(0,0,0,.5)'
				/>
			</div>
		</>
	);
};

export default FloorPlan;
