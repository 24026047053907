import './Common.scss';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Typography,Card,Grid,CardContent} from '@material-ui/core';

const useStyles = makeStyles({
    root: {
      marginTop:'2rem',
      marginBottom:'2rem',
      flexGrow: 1,
      
    },
    grid:{
        ["@media (max-width:1024px)"]: {
           justifyContent:'center', 
        }
    },
    card:{
        height:170,
       
        ["@media (max-width:600px)"]: {
            height:120,
            width:110,
            
            
        }
       
    }
})

const Amenities=(props)=>{
    const classes=useStyles();
    const getList=props.amenities.map((amenities)=>{
        const {icon,name}=amenities;
    return(
          <Grid item xs={5} sm={5} md={2} key={name}>
                         <Card className="card-block">
                         <CardContent className={classes.card}>
                             <div className="inner-block">
                            <span className="icon-amenities">{icon}</span> 
                             <p>{name}</p>
                             </div>
                         </CardContent>
                         </Card>
         </Grid>
              
       )
    })
    return(
        <div className="outer-header-div">
          <div className="section-title-block">
              
              <Typography variant="h5" align="center" >
               Amenities
               </Typography>
               <div className="slide-border">
               <div className="before"></div>
               <hr className="hr"/>
               <div className="after"></div>
               </div>
         </div>
        
         <div className={classes.root}>
             <Grid container justify="left" alignContent="center" spacing={2} className={classes.grid} >
             {getList}
             </Grid>
         </div>
        
        </div>
    )
}

export default Amenities;