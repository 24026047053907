import React, { Component } from "react";
import "survey-react/survey.css";
import * as Survey from "survey-react";
import "./Survey.scss";

var defaultThemeColors = Survey.StylesManager.ThemeColors["modern"];

defaultThemeColors["$main-color"] = "#e00000"; //purple- the theme's underlines and button'
defaultThemeColors["$main-hover-color"] = "#9e9e9e";
defaultThemeColors["$body-container-background-color"] = "#f8f8f8";

Survey.StylesManager.applyTheme("modern");

var json = {
  title: "Customer Feedback Form",
  description:
    "We kindly ask you to fill in this feedback form. The feedback will be forwarded to the real estate developer you dealt with in order for them to develop their operations",
  pages: [
    {
      questions: [
        {
          type: "matrix",
          name: "Quality",
          title:
            "Please indicate if you agree or disagree with the following statements",
          columns: [
            {
              value: 1,
              text: "Strongly Disagree",
            },
            {
              value: 2,
              text: "Disagree",
            },
            {
              value: 3,
              text: "Neutral",
            },
            {
              value: 4,
              text: "Agree",
            },
            {
              value: 5,
              text: "Strongly Agree",
            },
          ],
          rows: [
            {
              value: "Were you satisfied with the real estate agency",
              text: "Were you satisfied with the real estate agency",
            },
            {
              value: "communication",
              text: "communication",
            },
            {
              value: "fluency of the property display",
              text: "fluency of the property display",
            },
            {
              value: "service",
              text: "service",
            },
          ],
        },
        {
          type: "rating",
          name: "satisfaction",
          title: "How satisfied are you with the Property location?",
          isRequired: true,
          mininumRateDescription: "Not Satisfied",
          maximumRateDescription: "Completely satisfied",
        },
        {
          type: "rating",
          name: "recommend friends",
          visibleIf: "{satisfaction} > 3",
          title:
            "How likely are you to recommend the Property to a friend or co-worker?",
          mininumRateDescription: "Will not recommend",
          maximumRateDescription: "I will recommend",
        },
        {
          type: "comment",
          name: "suggestions",
          title: "What would make you more satisfied with the Service?",
        },
      ],
    },
    {
      questions: [
        {
          type: "radiogroup",
          name: "price to competitors",
          title: "Compared to our competitors, do you feel the Property is",
          choices: [
            "Less expensive",
            "Priced about the same",
            "More expensive",
            "Not sure",
          ],
        },
        {
          type: "radiogroup",
          name: "price",
          title: "Do you feel our current price is merited by our property?",
          choices: [
            "correct|Yes, the price is about right",
            "low|No, the price is too low for your product",
            "high|No, the price is too high for your product",
          ],
        },
        {
          type: "multipletext",
          name: "pricelimit",
          title: "What is the... ",
          items: [
            {
              name: "mostamount",
              title: "Most amount you would every pay for a property like ours",
            },
            {
              name: "leastamount",
              title: "The least amount you would feel comfortable paying",
            },
          ],
        },
      ],
    },
    {
      questions: [
        {
          type: "text",
          name: "email",
          title:
            "Thank you for taking our survey. Your survey is almost complete, please enter your email address in the box below, then press the 'Submit' button.",
        },
      ],
    },
  ],
};

class SurveyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onCompleteComponent = this.onCompleteComponent.bind(this);
  }

  onCompleteComponent = (result) => {
    this.setState({
      isCompleted: true,
    });
    console.log(result.valuesHash);
  };

  render() {
    var surveyRender = !this.state.isCompleted ? (
      <Survey.Survey
        json={json}
        showCompletedPage={false}
        onComplete={this.onCompleteComponent}
      />
    ) : null;

    var onSurveyCompletion = this.state.isCompleted ? (
      <div class="confirmation-text">
        <h3>Thank you for your feedback!!</h3>
      </div>
    ) : null;
    return (
      <div>
        {surveyRender}
        {onSurveyCompletion}
      </div>
    );
  }
}

export default SurveyPage;
