import React from "react";
import "./faq.scss";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { questionlist } from "../../Data/FaqQuestionList";
import { theme } from "../CustomTheme";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import faqlogo from "../../assets/faq.gif";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "4rem",
    maxWidth: "800px",
    margin: "0 auto",
    padding: "0 10px",
    boxSizing: "border-box",
    minHeight: "80vh",
    [theme.breakpoints.up("md")]: {
      padding: "0",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "600",
  },
  colorleft: {
    borderLeft: "5px solid #e00000",
    background: "#8e9eab",
    background: "-webkit-linear-gradient(to right, #eef2f3, #8e9eab)",
    background: "linear-gradient(to right, #eef2f3, #8e9eab)",
  },
}));

const Faq = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const getList = questionlist.map((ql) => {
    return (
      <div class="accordion-wrapper" key={ql.id}>
        <Accordion
          className={classes.colorleft}
          expanded={expanded === `${ql.id}`}
          onChange={handleChange(`${ql.id}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
            aria-controls="panel1a-content"
            id="panel1d-header"
          >
            <Typography className={classes.heading}>{ql.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrowRightIcon color="secondary" />
            <Typography>{ql.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  });
  return (
    <ThemeProvider theme={theme}>
      <img src={faqlogo} alt="faq's..." class="faq-gif" />
      <div className={classes.root}>
        <Typography variant="h3" class="faq-header">
          Frequently Asked Questions
        </Typography>
        <h5 class="faq-sub-header">
          You can use this guide to familiarize yourself with rules, laws and
          other important information relating to your property.
        </h5>
        {getList}
      </div>
    </ThemeProvider>
  );
};

export default Faq;
