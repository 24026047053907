import "./Common.scss";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, Grid, CardContent } from "@material-ui/core";

const useStyles = makeStyles({
	root: {
		marginTop: "2rem",
		marginBottom: "2rem",
		flexGrow: 1,
	},
	grid: {
		["@media (max-width:1024px)"]: {
			justifyContent: "center",
		},
	},
	card: {
		height: 240,
		minwidth: 250,
	},
});

const PriceAndPlan = (props) => {
	const classes = useStyles();
	const getList = props.prices.map((property) => {
		const { topology, price, status } = property;
		return (
			<Grid item xs={10} sm={5} md={3} key={status}>
				<Card className='card-block'>
					<CardContent className={classes.card}>
						<div className='type-block'>
							<p>Type</p>
							<h3>{topology}</h3>
						</div>

						<div className='price-block'>
							<p>Starting</p>
							<h3>{price}</h3>
						</div>

						<div className='status-block'>
							<p>Status</p>
							<h3>{status}</h3>
						</div>
					</CardContent>
				</Card>
			</Grid>
		);
	});
	return (
		<div className='outer-header-div'>
			<div className='section-title-block'>
				<Typography variant='h5' align='center'>
					Prices
				</Typography>
				<div className='slide-border'>
					<div className='before'></div>
					<hr className='hr' />
					<div className='after'></div>
				</div>
			</div>
			<div className={classes.root}>
				<Grid
					container
					justify='left'
					alignContent='center'
					spacing={4}
					className={classes.grid}
				>
					{getList}
				</Grid>
			</div>
		</div>
	);
};

export default PriceAndPlan;
