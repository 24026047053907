import React from "react";
import "./footer.scss";
import Brand from "../Navbar/Brand";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import MapIcon from "@material-ui/icons/Map";
import history from "../../history";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-distributed">
      <div className="footer-left">
        <span
          className="footerlogo"
          onClick={() => {
            history.push("/");
          }}
        >
          <Brand />
        </span>
        <p className="footer-links">
          <Link to="disclaimer" className="link-1">
            Disclaimer
          </Link>
          <Link to="/terms-of-use">Terms of Use</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/contact-us">Contact</Link>
          <Link to="/faq">FAQ</Link>
        </p>
        <p className="footer-company-name">Raj Realty Group © 2020</p>
      </div>
      <div className="footer-center">
          <MapIcon className="icon" />
          <p>
            <a target="_blank"
                href="https://www.google.co.in/maps/place/Raj+Realty/@19.2058299,72.7547997,12z/data=!4m8!1m2!2m1!1sraj+realty+!3m4!1s0x3be7b04ef51677cd:0xa7d56d5199fc87f!8m2!3d19.278635!4d72.8734209">
              Raj Florenza, Vijay Park, Near Amber Plaza Hall, OPP. MBMC Garden, Mira Road(E), 401 107.
            </a>
          </p>
          <PhoneIcon className="icon" />
          <p>
            <a href="tel:02228554700">022 2855 4700/1/3</a>
          </p>
          <br />
          <PhoneIphoneIcon className="icon" />
          <p>
            <a href="tel:+917400001748">+91 7400001748/9</a>
          </p>
          <br></br>
          <MailOutlineIcon className="icon" />
          <p>
            <a href="mailto:enquiry@rajrealtygroup.com">
              enquiry@rajrealtygroup.com
            </a>
          </p>
      </div>
      <div className="footer-right">
        <p className="footer-company-about">
          <span>About RAJ REALTY</span>
          We are a real estate development company formed in the year 2005-06
          and have been growing rapidly with over 10 projects completed in
          westerns suburbs of Mumbai and beyond.
        </p>
        <div className="footer-icons">
          <a
            href="https://www.facebook.com/Raj-Realty-Group-115709296929103/"
            target="_blank"
          >
            <FacebookIcon viewBox="0 -3 24 24" />
          </a>
          <a href="https://www.instagram.com/rajrealtygroup/" target="_blank">
            <InstagramIcon viewBox="0 -3 26 26" />
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
