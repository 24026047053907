import React from "react";
import GardenView from "../../images/projectDetails/ShreeShaswat/GardenView.jpg";
import FullView from "../../images/projectDetails/ShreeShaswat/FullView.jpg";
import GateView from "../../images/projectDetails/ShreeShaswat/GateView.jpg";
import TopView from "../../images/projectDetails/ShreeShaswat/TopView.jpg";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SchoolIcon from "@material-ui/icons/School";
import PoolIcon from "@material-ui/icons/Pool";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";

export const heroImage = {
	imageUrl:GardenView,
	pdfUrl:""
}

export const locationDetails = {
	name: "Shree Shashwat",
	lat: "19.4682019",
	lng: "72.7978063",
	googleMapUrl:
		"https://www.google.com/maps/place/Shree+Shashwat+A4+Co-op+Housing+Society+Ltd./@19.4682019,72.7978063,17z/data=!3m1!4b1!4m8!1m2!2m1!1sshree+shashwat+virar!3m4!1s0x0:0x282de1dd085a0da6!8m2!3d19.4682019!4d72.7999947",
};

export const photos = [
	{
		src: TopView,
		width: 4,
		height: 3,
		title: "Top View",
	},
	{
		src: GateView,
		width: 4,
		height: 3,
		title: "Gate View",
	},
	{
		src: GardenView,
		width: 4,
		height: 3,
		title: "Garden View",
	},
	{
		src: FullView,
		width: 4,
		height: 3,
		title: "Full View",
	},
];

export const amenities = [
	{
		icon: <SchoolIcon fontSize='large' />,
		name: "Schools Nearby",
	},
	{
		icon: <DirectionsBusIcon fontSize='large' />,
		name: "Bus-stops Nearby",
	},
	{
		icon: <ShoppingCartIcon fontSize='large' />,
		name: "Markets Nearby",
	},
	{
		icon: <FitnessCenterIcon fontSize='large' />,
		name: "Gymnasium",
	},
	{
		icon: <PoolIcon fontSize='large' />,
		name: "Swimming Pool",
	},
];

export const propertyOverview = {
	propertyName: "Shree Shashwat",
	overview:
		"For those who wish to reside in an abode within the perfect harmony of peace and technology, Shree Shashwat is the perfect choice for you. The entire project has been crafted to suit your modern lifestyle for ensuring the fact that you enjoy a stress free lifestyle. Armed with all the exclusive and intricate features, Shree Shashwat can be rightly termed as a new age palace for modern monarchs. All the basic needs and requirements of life fulfilled here and you can live a stress free life always.",
	facilities:
		"Premium quality flooring.\nPremium quality wall tiles in all bathrooms and kitchen.\nGranite kitchen platform with S.S. Sink.\nBranded fittings in all bathrooms.\nConcealed copper wiring with branded electrical fitting\nAnodised / Powder Coated Aluminium sliding windows with mosquito net.\nProvision for cable TV and Telephone connection.",
	maharerano: "P51700019990",
};
