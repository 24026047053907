import "./Common.scss";
import React from "react";
import { ButtonContainedSecondary } from "../../Common/Button";

import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import EnquiryComponent from "../../FeaturedProjects/EnquiryComponent";

const HeroImage = (props) => {
	const { imageUrl, pdfUrl, propertyName } = props.heroImage;

	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const hasPdf = () => {
		if (pdfUrl) {
			return (
				<ButtonContainedSecondary
					href={pdfUrl}
					className='download-button'
					download
					onClick={handleOpen}
				>
					E-brochure
					<GetAppRoundedIcon className='download-icon' />
				</ButtonContainedSecondary>
			);
		}
	};
	return (
		<>
			<div className='project-detail'>
				<img src={imageUrl} className='hero-img' />
				{hasPdf()}
				<EnquiryComponent
					openModal={open}
					closeModal={handleClose}
					propertyName={propertyName}
				/>
			</div>
		</>
	);
};

export default HeroImage;
