import React from "react";
import RajEstate from "../../images/projectDetails/RajEstate/RajEstate.jpg";
import DeckIcon from "@material-ui/icons/Deck";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";

export const heroImage = {
	imageUrl:RajEstate,
	pdfUrl:""
}

export const locationDetails = {
	name: "Raj Estate",
	lat: "19.2717611",
	lng: "72.8863272",
	googleMapUrl:
		"https://www.google.com/maps/place/Raj+Estate,+Jari+Mari+Mandir+Rd,+Kashigaon,+Mira+Road,+Mira+Bhayandar,+Maharashtra+401107/@19.2717611,72.8863272,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b07d6acc6089:0x97331ec8ebae20c7!8m2!3d19.2717611!4d72.8885159",
};

export const photos = [
	{
		src: RajEstate,
		width: 8,
		height: 3,
		title: "Full View",
	},
];

export const amenities = [
	{
		icon: <DeckIcon fontSize='large' />,
		name: "Childrens Play Area",
	},
	{
		icon: <FitnessCenterIcon fontSize='large' />,
		name: "Gymnasium",
	},
];

export const propertyOverview = {
	propertyName: "Raj Estate",
	overview:
		"Raj Estate offers exclusive apartments and is an optimal blend of all desirable elements. This project gives you the best of nature and the city. This plush residency comprises of homes that matches your myriad taste. The project houses amenities and features that will help you maintain a well-balanced lifestyle. Raj Estate offers well ventilated flats to suit your requirements. The interiors are thoughtfully designed to maintain a fine sense of harmony and exclusivity. It is a beautiful mix of luxury, comfort and convenience which makes Raj Estate the ultimate choice.",
	facilities:
		"Premium quality flooring.\nPremium quality wall tiles in all bathrooms and kitchen.\nGranite kitchen platform with S.S. Sink.\nBranded fittings in all bathrooms.\nConcealed copper wiring with branded electrical fitting\nAnodised / Powder Coated Aluminium sliding windows with mosquito net.\nProvision for cable TV and Telephone connection.",
	maharerano: "P51700019990",
};
