import './slider.scss';
import React from 'react';

import 'react-awesome-slider/dist/styles.css';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import CoreStyles from 'react-awesome-slider/dist/styles.css';
import history from '../../history';
import { slides } from '../../Data/MainSliderProjectList';
import { ButtonContainedPrimary } from '../Common/Button';
import MoreIcon from '@material-ui/icons/More';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const GetSlides = () => {
	return slides.map((slide) => (
		<div key={slide.title} className="property-image" data-src={slide.image}>
			<div className="property-details" data-type="caption">
				<ButtonContainedPrimary
					endIcon={<MoreIcon />}
					style={{ marginRight: '2%' }}
					onClick={() => {
						history.push(slide.url);
					}}
				>
					{slide.title}
				</ButtonContainedPrimary>
			</div>
		</div>
	));
};

const getSlider = () => {
	return (
		<AutoplaySlider
			play={true}
			interval={3000}
			cssModule={[ CoreStyles ]}
			className="aws-btn"
			fillParent={true}
			bullets={false}
		>
			{GetSlides()}
		</AutoplaySlider>
	);
};

export default function Slider() {
	return <div className="main-slider">{getSlider()}</div>;
}
