import "./ContactForm.scss";
import React from "react";
import { Field, reduxForm } from "redux-form";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonContainedPrimary } from "../../components/Common/Button";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			// width: '25ch',
		},
		padding: "15px",
	},
}));

const validate = (values) => {
	const errors = {};
	if (!values.name) {
		errors.name = "Please enter your name.";
	}
	if (!values.email) {
		errors.email = "Please enter your email id.";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Please enter valid email address";
	}
	if (!values.mobileNumber) {
		errors.mobileNumber = "Please enter mobile number";
	} else if (isNaN(Number(values.mobileNumber))) {
		errors.mobileNumber = "Please enter valid mobile number";
	} else if (values.mobileNumber.length !== 10) {
		errors.mobileNumber = "Please enter valid 10 digit mobile number";
	}
	return errors;
};

const renderField = ({ input, label, type, meta: { touched, error } }) => (
	<div>
		<TextField
			fullWidth='true'
			variant='outlined'
			{...input}
			placeholder={label}
			type={type}
			{...(touched &&
				error && { error: true, helperText: error } && {
					error: true,
					helperText: error,
				})}
		/>
	</div>
);

const ContactForm = (props) => {
	const { handleSubmit, submitting } = props;
	const classes = useStyles();
	return (
		<form className={classes.root} onSubmit={handleSubmit}>
			<Field name='name' type='text' component={renderField} label='Name' />
			<Field
				name='email'
				type='email'
				component={renderField}
				label='Email Id'
			/>
			<Field
				name='mobileNumber'
				type='number'
				component={renderField}
				label='Mobile Number'
			/>
			<div className='button-div'>
				<ButtonContainedPrimary
					type='submit'
					className='submit-button'
					disabled={submitting}
				>
					Submit
				</ButtonContainedPrimary>
			</div>
		</form>
	);
};

export default reduxForm({
	form: "contactForm", // a unique identifier for this form
	validate, // <--- validation function given to redux-form
})(ContactForm);
