import React from "react";
import RajExotica from "../../images/projectDetails/RajExotica/RajExotica.jpg";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SchoolIcon from "@material-ui/icons/School";
import PoolIcon from "@material-ui/icons/Pool";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";

export const heroImage = {
	imageUrl:RajExotica,
	pdfUrl:""
}

export const locationDetails = {
	name: "Raj Exotica",
	lat: "19.2827038",
	lng: "72.8808383",
	googleMapUrl:
		"https://www.google.com/maps/place/Raj+Exotica+Co-Operative+Housing+Society/@19.2827038,72.8808383,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b06f0dc8eff1:0x9cde098fe481a3e8!8m2!3d19.2827038!4d72.883027",
};

export const photos = [
	{
		src: RajExotica,
		width: 10,
		height: 3,
		title: "Full View",
	},
];

export const amenities = [
	{
		icon: <SchoolIcon fontSize='large' />,
		name: "Schools Nearby",
	},
	{
		icon: <DirectionsBusIcon fontSize='large' />,
		name: "Bus-stops Nearby",
	},
	{
		icon: <ShoppingCartIcon fontSize='large' />,
		name: "Markets Nearby",
	},
	{
		icon: <FitnessCenterIcon fontSize='large' />,
		name: "Gymnasium",
	},
	{
		icon: <PoolIcon fontSize='large' />,
		name: "Swimming Pool",
	},
];

export const propertyOverview = {
	propertyName: "Raj Exotica",
	overview:
		"Raj Exotica is an elite residency that introduced the concept of aristocratic living. It is a lavish residential project nestled in the lush green bounty of nature. It is the perfect destination where the twittering of birds will wake you up in the morning and peaceful nights will calm you down. Experience the true essence of beauty in your home that resonates with luxury, style and sophistication. The beautifully crafted living spaces promise you and yourfamily a lifetime of blissful living in tune with nature. The optimum utilization and immaculate detailing truly signifies in every corner of the project. All the amenities of Exotica epitomize a good life adorned with luxury and lifestyle that will make your lifecomfortable, happy and peaceful forever.",
	facilities:
		"Premium quality flooring.\nPremium quality wall tiles in all bathrooms and kitchen.\nGranite kitchen platform with S.S. Sink.\nBranded fittings in all bathrooms.\nConcealed copper wiring with branded electrical fitting\nAnodised / Powder Coated Aluminium sliding windows with mosquito net.\nProvision for cable TV and Telephone connection.",
	maharerano: "P51700019990",
};
