import React from "react";
import RajAntila from "../../images/completedProjects/RajAntila.jpg";
import EntranceLobby from "../../images/projectDetails/RajAntila/EntranceLobby.jpg";
import FullView from "../../images/projectDetails/RajAntila/FullView.jpg";
import GateView from "../../images/projectDetails/RajAntila/GateView.jpg";
import Lobby from "../../images/projectDetails/RajAntila/Lobby.jpg";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PoolIcon from "@material-ui/icons/Pool";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";

export const heroImage = {
	imageUrl:RajAntila,
	pdfUrl:""
}

export const locationDetails = {
	name: "Raj Antila",
	lat: "19.2875775",
	lng: "72.868331",
	googleMapUrl:
		"https://www.google.com/maps/place/Raj+Antila,+madam/@19.2875775,72.868331,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b0413c50ad0d:0xbc0cc184cfaeac49!8m2!3d19.2875775!4d72.8705197",
};

export const photos = [
	{
		src: EntranceLobby,
		width: 4,
		height: 3,
		title: "Entrance Lobby",
	},
	{
		src: Lobby,
		width: 4,
		height: 3,
		title: "Lobby",
	},
	{
		src: GateView,
		width: 4,
		height: 3,
		title: "Gate View",
	},
	{
		src: FullView,
		width: 4,
		height: 3,
		title: "Full View",
	},
];

export const amenities = [
	{
		icon: <DirectionsBikeIcon fontSize='large' />,
		name: "Cycling & Jogging track",
	},
	{
		icon: <PoolIcon fontSize='large' />,
		name: "Swimming Pool",
	},
	{
		icon: <VerifiedUserIcon fontSize='large' />,
		name: "24x7 Security",
	},
	{
		icon: <FitnessCenterIcon fontSize='large' />,
		name: "Gymnasium",
	}
];

export const propertyOverview = {
	propertyName: "Raj Antila",
	overview:
		"Raj Antila, a grand residential project aims at providing architecturally sound residencies at the most affordable rates. The elegantly designed homes come equipped with many high-end amenities and have an enviable finesse. The project is also designed carefully and artistically that promises a pacific environment for the residents and their loved ones. It offers the occupants a life away from the hassles and in a pollution free environment. Special attention has been given to ensure privacy for the families at Raj Antila.",
	facilities:
		"Premium quality flooring.\nPremium quality wall tiles in all bathrooms and kitchen.\nGranite kitchen platform with S.S. Sink.\nBranded fittings in all bathrooms.\nConcealed copper wiring with branded electrical fitting\nAnodised / Powder Coated Aluminium sliding windows with mosquito net.\nProvision for cable TV and Telephone connection.",
	maharerano: "P51700019990",
};