import React from "react";
import Counter from "./Counter/Counter";
import Slider from "./FeaturedProjects/Slider";
import MainSlider from "./MainSlider/Slider";
import AllProjects from "./ProjectListing/AllProjects";

class Home extends React.Component {
	render() {
		return (
			<>
				<MainSlider />
				<Slider />
				<AllProjects />
				<Counter />
			</>
		);
	}
}

export default Home;
