import RajFlorenza from "../../images/onGoingProjects/RajFlorenza.jpg";
import RajFlorenzaPDF from "../../assets/pdf/RajFlorenza.pdf";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import VideocamIcon from "@material-ui/icons/Videocam";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import DeckIcon from "@material-ui/icons/Deck";
import KitchenIcon from "@material-ui/icons/Kitchen";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import React from "react";

import FullView1 from "../../images/projectDetails/RajFlorenza/FullView1.jpg";
import FullView2 from "../../images/projectDetails/RajFlorenza/FullView2.jpg";
import FullView3 from "../../images/projectDetails/RajFlorenza/FullView3.jpg";
import FloorPlan from "../../images/floorPlans/RajFlorenza.jpg";

export const heroImage = {
	imageUrl: RajFlorenza,
	pdfUrl: RajFlorenzaPDF,
	propertyName: "Raj Florenza",
};

export const locationDetails = {
	name: "Raj Florenza",
	lat: "19.2784177",
	lng: "72.8717294",
	googleMapUrl:
		"https://www.google.co.in/maps/place/Raj+Florenza/@19.2784177,72.8717294,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b1ccf850e09b:0xa6b1da91bd390da3!8m2!3d19.2784126!4d72.8739181",
};

export const photos = [
	{
		src: FullView1,
		width: 6,
		height: 3,
		title: "Front View",
	},
	{
		src: FullView2,
		width: 4,
		height: 4,
		title: "Full View",
	},
	{
		src: FullView3,
		width: 4,
		height: 4,
		title: "Full View",
	},
];

export const prices = [
	{
		topology: "1BHK",
		price: "61.6 Lakh++",
		status: "A&C wing ready possession and O.C Received.",
	},
	{
		topology: "2BHK",
		price: "91.4 Lakh++",
		status: "A&C wing ready possession and O.C Received.",
	},
	{
		topology: "1BHK",
		price: "61.6 Lakh++",
		status: "B wing under construction",
	},
	{
		topology: "2BHK",
		price: "91.4 Lakh++",
		status: "B wing under construction",
	},
];

export const amenities = [
	{
		icon: <MeetingRoomIcon fontSize='large' />,
		name: "Video door camera",
	},
	{
		icon: <KitchenIcon fontSize='large' />,
		name: "Modular Kitchen",
	},
	{
		icon: <DeckIcon fontSize='large' />,
		name: "Garden",
	},
	{
		icon: <VideocamIcon fontSize='large' />,
		name: "CCTV Camera",
	},
	{
		icon: <PhoneInTalkIcon fontSize='large' />,
		name: "Intercom facility",
	},
	{
		icon: <SettingsInputAntennaIcon fontSize='large' />,
		name: "TV Cable and Telephone connections",
	},
];

export const propertyOverview = {
	propertyName: "Raj Florenza",
	overview:
		"A setting for serenity \n\nIntroducing RAJ Florenza, a residential complex at Mira Road, a beautifully planned and executed offering by Raj Realty Group, With a bold striking facade on the outside and alluring serenity within this fortified bastion of modern pleasures and luxury hosts specious well appointed 1-2 bhk apartments.",
	facilities:
		"Premium quality flooring.\nPremium quality wall tiles in all bathrooms and kitchen.\nQuartz artificial marble with S.S. Sink. Jaquar fittings in all bathrooms.\nAnodised Aluminium sliding windows.\nProviding for cable T.V. and Telephone connection.\nVideo door phone Intercom facility.\nCCTV Camera",
	maharerano: "P51700001219",
	topology: "Topology: 1 & 2 BHK Apartments",
	status:
		"Status: Wing A and C are ready to move with OC. Wing B is under construction.",
};

export const floorplan = FloorPlan;
