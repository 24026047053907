import "./Common.scss";
import React from "react";
import { Typography, Grid } from "@material-ui/core";
import MahareraLogo from "../../../assets/MahareraLogo1.jpg";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import EnquiryComponent from "../../FeaturedProjects/EnquiryComponent";

const PropertyOverview = (props) => {
	const {
		propertyName,
		overview,
		facilities,
		maharerano,
		topology,
		status,
		ecInfoPDF,
	} = props.overview;
	const list = facilities.split("\n");

	const listServiceItem = list.map((item) => {
		return (
			<>
				<li>
					<span>{item}</span>
				</li>
			</>
		);
	});

	const ecInfo = () => {
		if (ecInfoPDF) {
			return (
				<div className='ec-info'>
						<p>
						EC compliance
						<br></br>
						{console.log(ecInfoPDF)}
						<a href={ecInfoPDF[0]} target="_blank">1.Environmental Clearance<GetAppRoundedIcon className='download-icon'/></a><br></br>
						<a href={ecInfoPDF[1]} target="_blank">2.Paper Notice<GetAppRoundedIcon className='download-icon'/></a><br></br>
						<a href={ecInfoPDF[2]} target="_blank">3.Audit Report<GetAppRoundedIcon className='download-icon'/></a><br></br>
						</p>
				</div>
				);
		}
	};

	const [open, setOpen] = React.useState(true);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div className='outer-header-div'>
			<EnquiryComponent openModal={open} closeModal={handleClose} />
				<div className='section-title-block'>
					<Typography variant='h5' align='center'>
						{propertyName}
					</Typography>
					<div className='slide-border'>
						<div className='before'></div>
						<hr className='hr' />
						<div className='after'></div>
					</div>
				</div>
			</div>
			<Grid container spacing={3}>
				<Grid item sm={12} md={6}>
					<div className='overview-text'>
						<span>{overview}</span>
					</div>
					<div className='status-info'>
						<ul>
							{topology ? (
								<li>
									<ArrowRightIcon viewBox='0 -6 24 24' />
									{topology}
								</li>
							) : null}
							{status ? (
								<li>
									<ArrowRightIcon viewBox='0 -6 24 24' />
									{status}
								</li>
							) : null}
						</ul>
					</div>
					<div className='maharera-info'>
							<img src={MahareraLogo} />
							<p>
							MAHA RERA Registration No: <span>{maharerano}</span>. Available on
							the website{" "}
							<a target='_blank' href='https://maharera.mahaonline.gov.in'>
								https://maharera.mahaonline.gov.in
							</a>
							</p>
					</div>
				</Grid>
				<Grid item sm={12} md={6}>
					<ul className='service-item'>{listServiceItem}</ul>
					{ecInfo()}
				</Grid>
			</Grid>
		</>
	);
};

export default PropertyOverview;
