import RajHomes127 from "../../images/onGoingProjects/RajHomes127.jpg";
import RajHomesPDF127 from "../../assets/pdf/127RajHomes.pdf";
import DeckIcon from "@material-ui/icons/Deck";
import SchoolIcon from "@material-ui/icons/School";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import React from "react";

import EntranceLobby from "../../images/projectDetails/127RajHomes/EntranceLobby.jpg";
import MiraRoadBirdView from "../../images/projectDetails/127RajHomes/MiraRoadBirdView.jpg";
import FrontView from "../../images/projectDetails/127RajHomes/MiraRoadCam.jpg";
import SideView from "../../images/projectDetails/127RajHomes/MiraRoadCam02.jpg";
import Pool from "../../images/projectDetails/127RajHomes/MiraRoadCam4.jpg";
import BHK1 from "../../images/projectDetails/127RajHomes/1bhk.jpg";
import BHK2 from "../../images/projectDetails/127RajHomes/2bhk.jpg";
import MiraRoadTopView from "../../images/projectDetails/127RajHomes/MiraRoadTopView.jpg";
import FloorPlan from "../../images/floorPlans/127RajHomes.PNG";
import AWing from "../../images/barcodes/127RajHomesAWing.jpg";
import BAndCWing from "../../images/barcodes/127RajHomesBAndCWing.jpg";
import DWing from "../../images/barcodes/127RajHomesDWing.jpg";

export const heroImage = {
	imageUrl: RajHomes127,
	pdfUrl: RajHomesPDF127,
	propertyName: "127 Raj Homes",
};

export const locationDetails = {
	name: "127 Raj Homes",
	lat: "19.2367741",
	lng: "72.8340925",
	googleMapUrl:
		"https://www.google.co.in/maps/place/Raj+homes+127/@19.2367741,72.8340925,13z/data=!4m5!3m4!1s0x3be7b1f90cb6e1e7:0x1558426a1f422512!8m2!3d19.2675102!4d72.8719321",
};

export const photos = [
	{
		src: AWing,
		width: 4,
		height: 4,
		title: "127 Raj Homes(A wing)",
	},
	{
		src: BAndCWing,
		width: 4,
		height: 4,
		title: "127 Raj Homes(B and C wing)",
	},
	{
		src: DWing,
		width: 4,
		height: 4,
		title: "127 Raj Homes(D wing)",
	},
	{
		src: BHK1,
		width: 6,
		height: 4,
		title: "1 BHK",
	},
	{
		src: BHK2,
		width: 6,
		height: 4,
		title: "2 BHK",
	},
	{
		src: EntranceLobby,
		width: 6,
		height: 4,
		title: "Entrance Lobby",
	},
	{
		src: MiraRoadBirdView,
		width: 5,
		height: 4,
		title: "Bird View",
	},
	{
		src: FrontView,
		width: 5,
		height: 4,
		title: "Front View",
	},
	{
		src: SideView,
		width: 4,
		height: 4,
		title: "Side View",
	},
	{
		src: Pool,
		width: 4,
		height: 4,
		title: "Pool",
	},
	{
		src: MiraRoadTopView,
		width: 8,
		height: 4,
		title: "Top View",
	},
];

// export const prices = [
// 	{
// 		topology: "1BHK",
// 		price: "38 Lacs++",
// 		status: "45% work completion.",
// 	},
// ];

export const amenities = [
	{
		icon: <DeckIcon fontSize='large' />,
		name: "Garden",
	},
	{
		icon: <SchoolIcon fontSize='large' />,
		name: "Schools Nearby",
	},
	{
		icon: <DirectionsBusIcon fontSize='large' />,
		name: "Bus-stops Nearby",
	},
	{
		icon: <ShoppingCartIcon fontSize='large' />,
		name: "Markets Nearby",
	},
];

export const propertyOverview = {
	propertyName: "127 Raj Homes",
	overview: "127 Raj Homes Reviving the Roots of pure living",
	facilities:
		"1.5M Wide Jogging Track.\nDriveway - 6M. Wide.\nDrop off/ Pick up point.\nChitchat Corner.\nKid's play area.\nMain pool & Kids pool with pool side deck.\nMultifunctional lawn.\nGazebo seating for senior citizens.\nIndoor Garden / Zen Garden.\nSeating below pergola.",
	maharerano: "P51700034776(A wing), P51700050118(B & C wing), P5170005015(D wing)",
	topology: "Topology: 1 & 2 BHK Apartments",
	status: "Status: Under Construction",
};

export const floorplan = FloorPlan;