import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import { currentProjects, completedProjects } from "../../Data/listconstants";
import CardComponent from "./CardComponent";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		["@media (max-width:376px)"]: {
			overflowX: "hidden",
		},
		["@media (min-width:990px)"]: {
			marginLeft: 60,
			marginRight: 60,
		},
		marginTop: 100,
		marginBottom: 50,
		paddingLeft: 20,
		paddingRight: 20,
	},
}));

const ProjectListing = () => {
	const classes = useStyles();
	let location = useLocation();
	let projects;

	if (location.pathname.includes("currentprojects")) {
		projects = currentProjects;
	} else if (location.pathname.includes("completedprojects")) {
		projects = completedProjects;
	} else {
		projects = [...currentProjects, ...completedProjects];
	}

	const getList = projects.map((project) => {
		const {
			title,
			shareDescription,
			location,
			status,
			description,
			imageurl,
			url,
			amenities,
		} = project;

		return (
			<Grid key={title} item xs={12} sm={6} md={4}>
				<CardComponent
					title={title}
					shareDescription={shareDescription}
					location={location}
					status={status}
					description={description}
					imgSrc={imageurl}
					url={url}
					amenities={amenities}
				/>
			</Grid>
		);
	});
	return (
		<div className={classes.root}>
			<Grid container direction='row' spacing={5}>
				{getList}
			</Grid>
		</div>
	);
};

export default ProjectListing;
