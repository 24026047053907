import "./EnquiryComponent.scss";
import React from "react";
import { connect } from "react-redux";
import { submitForm } from "../../actions/contactForm";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import ContactForm from "../Enquiry/ContactForm";
import BorderColorTwoToneIcon from "@material-ui/icons/BorderColorTwoTone";

const useStyles = (theme) => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: "5px solid #707070",
		boxShadow: theme.shadows[5],

		width: "50%",

		["@media (max-width:500px)"]: {
			overflowY: "scroll",
			width: "85%",
		},
	},
	button: {
		margin: "15px 0",
		left: "45%",
		"&:hover": {
			color: "#fff",
		},
		["@media (max-width:500px)"]: {
			left: "40%",
		},
	},
});

class EnquiryComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			thankYouOpen: false,
		};
	}

	handleSubmit = (formValues) => {
		this.props.submitForm(formValues, this.props.propertyName);
		this.setState({ thankYouOpen: true });
		this.props.closeModal();
	};
	handleThankYouClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		this.setState({ thankYouOpen: false });
	};
	render() {
		const { classes, openModal, closeModal, propertyName } = this.props;
		return (
			<>
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					className={classes.modal}
					open={openModal}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={this.props.openModal}>
						<div className={classes.paper}>
							<CancelIcon onClick={closeModal} className='cancel-icon' />

							<h3 className='form-title'>
								<BorderColorTwoToneIcon
									viewBox='0 -2 24 24'
									className='Register-icon'
								/>
								Register your interest
							</h3>
							{propertyName ? (
								<h5 className='form-message'>
									You seems intrested in knowing more about{" "}
									<span className='form-message-propertyName'>
										{propertyName}
									</span>
									. Please help us with your contact details, Our team will get
									in touch with you soon.
								</h5>
							) : null}
							<ContactForm
								onSubmit={this.handleSubmit}
								closeModal={closeModal}
								classes={classes}
							/>
						</div>
					</Fade>
				</Modal>
				<Snackbar
					open={this.state.thankYouOpen}
					autoHideDuration={3000}
					onClose={this.handleThankYouClose}
				>
					<MuiAlert
						elevation={6}
						variant='filled'
						onClose={this.handleThankYouClose}
						severity='success'
					>
						Thank You. Our team will connect with you soon!
					</MuiAlert>
				</Snackbar>
			</>
		);
	}
}

export default connect(null, { submitForm })(
	withStyles(useStyles)(EnquiryComponent)
);
